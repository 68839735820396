import { CodeSourceTypes, CodeSourceTypesLabels } from 'src/types';
import { Icon } from 'src/components/Icon';

const SVG_SIZE = 24;

export const sourcesSet: Record<
  CodeSourceTypes,
  { title: string; icon: JSX.Element; order: number }
> = {
  [CodeSourceTypes.OPEN_AI]: {
    title: CodeSourceTypesLabels.OPEN_AI,
    icon: <Icon type="chatGPTLogo" size={SVG_SIZE} />,
    order: 2,
  },

  [CodeSourceTypes.GOOGLE]: {
    title: CodeSourceTypesLabels.GOOGLE,
    icon: <Icon type="googleGeminiProLogo" size={SVG_SIZE} />,
    order: 3,
  },

  [CodeSourceTypes.ANTHROPIC]: {
    title: CodeSourceTypesLabels.ANTHROPIC,
    icon: <Icon type="claudeLogo" size={SVG_SIZE} />,
    order: 4,
  },

  // TODO deprecated
  [CodeSourceTypes.NINJA_CODER_1]: {
    title: CodeSourceTypesLabels.NINJA_CODER_1,
    icon: <Icon type="ninjaIcon" size={SVG_SIZE} />,
    order: 1,
  },

  [CodeSourceTypes.CHAT_GPT_4]: {
    title: CodeSourceTypesLabels.CHAT_GPT4,
    icon: <Icon type="chatGPTLogo" size={SVG_SIZE} />,
    order: 5,
  },

  [CodeSourceTypes.GOOGLE_GEMINI_PRO]: {
    title: CodeSourceTypesLabels.GOOGLE_GEMINI_PRO,
    icon: <Icon type="googleGeminiProLogo" size={SVG_SIZE} />,
    order: 6,
  },

  [CodeSourceTypes.GOOGLE_PALM_2_TEXT_BISON_001]: {
    title: CodeSourceTypesLabels.GOOGLE_PALM2,
    icon: <Icon type="googlePalmLogo" size={SVG_SIZE} />,
    order: 7,
  },

  [CodeSourceTypes.ANTHROPIC_CLAUDE_2]: {
    title: CodeSourceTypesLabels.ANTHROPIC,
    icon: <Icon type="claudeLogo" size={SVG_SIZE} />,
    order: 8,
  },
};
