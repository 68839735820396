import { createContext, useRef, RefObject } from 'react';

export type ForwardRefContextType = {
  rootContainerRef: RefObject<HTMLDivElement> | null;
};

type ForwardRefProviderProps = {
  children: React.ReactNode;
};

/**
 * ForwardRefContext is workaround for pass refs to components without using ForwardRef.
 */
const ForwardRefContext = createContext<ForwardRefContextType>({
  rootContainerRef: null,
});

const ForwardRefContextProvider = ({ children }: ForwardRefProviderProps) => {
  const rootContainerRef = useRef<HTMLDivElement>(null);

  return (
    <ForwardRefContext.Provider
      value={{
        rootContainerRef,
      }}
    >
      {children}
    </ForwardRefContext.Provider>
  );
};

export { ForwardRefContextProvider };
export default ForwardRefContext;
