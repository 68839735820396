import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { Citation } from 'src/types';
import {
  CITATION_REGEX,
  CODE_LANGUAGE_REGEX,
  CODE_WITHOUT_NEWLINE_REGEX,
} from 'src/constants';
import { LinkMarkdown } from './components/LinkMarkdown';
import { CodeMarkdown } from './components/CodeMarkdown';
import styles from './Markdown.module.scss';
import { preprocessLaTeX } from 'src/utils/latex';

interface MarkdownProps {
  children: string;
  references?: Citation[];
}

/**
 * Markdown handles markdown for the following:
 * - Headings (H1, H2, H3), use hash (# Heading 1,  ## Heading 2,  ### Heading 3)
 * - Bold, use double asterisks (**Bold**)
 * - Italics, use single asterisks (*Italics*)
 * - Tables, use pipe (|) and hyphen (-)
 * - Lists, use hyphen (-) or asterisk (*)
 * - Links, use square brackets and parentheses ([Link](https://www.example.com))
 * - Images, use exclamation mark and square brackets (![Alt text](https://www.example.com/image.jpg))
 * - Blockquotes, use greater than sign (>)
 * - Code, use backticks (`) and triple backticks (```)
 * - Strikethrough, use double tilde (~~Strikethrough~~)
 * - Newlines, use double space and newline (  \n)
 * - Horizontal rules, use three hyphens (---)
 * - Task lists, use hyphen (-) and square brackets ([x] or [ ])
 * - Footnotes, use caret and square brackets (^[Footnote])
 * - Superscript, use caret and parentheses (^Superscript)
 * - Subscript, use tilde and parentheses (~Subscript)
 * - Abbreviations, use square brackets and parentheses ([HTML](https://www.example.com) or HTML)
 * - Definition lists, use colon (:) and hyphen (-)
 * - Math, use dollar sign ($) and backticks (`)
 * - Emoji, use colon (:) and hyphen (-)
 * - HTML, use angle brackets (<HTML>)
 * @param children
 * @returns
 */
export const Markdown = ({ children, references }: MarkdownProps) => {
  const updatedContent = useMemo(() => {
    const cleared = children
      .replace(/```([^\n]+)```/gm, (_, p1) => {
        p1 = p1.replace(/ ([#]|const|var|let)/gm, '\n$1');
        p1 = p1.replace(/([;] )/gm, '$1\n');
        return '```\n' + p1 + '\n```\n';
      })
      .replace(/\n\n(\d+)/g, (_, p1) => `\n\n${p1}`)
      .replace(/\n/gi, '  \n')
      .replace(CITATION_REGEX, (match) => `${match}(#)`)
      .replace(CODE_WITHOUT_NEWLINE_REGEX, '$1\n```')
      .replace(CODE_LANGUAGE_REGEX, '```$1\n')
      // (olha): it's a workaround. The library parses several consecutive spaces as code
      .replace(/ {3}/g, ' \u00a0');

    // Added for latex, when $ in the end of the string, regExp can't determinate it
    return cleared + ' ';
  }, [children]);

  const dataForRender = useMemo(
    () => preprocessLaTeX(updatedContent).slice(0, -1),
    [updatedContent],
  );

  return (
    <div className={styles.root}>
      <ReactMarkdown
        unwrapDisallowed
        remarkPlugins={[
          [remarkMath, { singleDollarTextMath: false }],
          breaks,
          gfm,
        ]}
        rehypePlugins={[
          [rehypeKatex, { output: 'html', errorColor: 'var(--nj-foreground)' }],
        ]}
        components={{
          a: ({ ...props }: ReactMarkdownProps) => (
            <LinkMarkdown references={references} {...props} />
          ),
          code: ({ ...props }: CodeProps) => <CodeMarkdown {...props} />,
        }}
      >
        {dataForRender}
      </ReactMarkdown>
    </div>
  );
};
