import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

export type Color = { darkTheme: string; lightTheme: string };

export type ColorScheme = {
  baseColor?: Color;
  highlightColor?: Color;
};

type ProgressBarProps = ColorScheme & {
  className?: string;
  count?: number;
};

export const ProgressBar = ({
  className,
  count = 1,
  baseColor,
  highlightColor,
}: ProgressBarProps) => {
  const { lightTheme: baseLight } = baseColor || {
    darkTheme: '#1d282d',
    lightTheme: '#e8eaea',
  };

  const { lightTheme: highlightLight } = highlightColor || {
    darkTheme: '#11181b',
    lightTheme: '#ffffff',
  };

  return (
    <div
      className={classNames('nj-thread-combined-card--progress-bar', className)}
    >
      <Skeleton
        baseColor={baseLight}
        highlightColor={highlightLight}
        className="nj-thread-combined-card--progress-bar-skeleton"
        duration={3}
        width="100%"
        count={count}
      />
    </div>
  );
};
