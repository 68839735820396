import logoHorizontal from 'src/images/logos/logo-dark.svg';
import { env } from 'src/env';

const loginLink = env.REACT_APP_NINJA_SHARED_LOGIN_URL;
const signupLink = env.REACT_APP_NINJA_SHARED_SIGNUP_URL;
const mainLink = env.REACT_APP_NINJA_SHARED_URL;

interface HeaderProps {
  withJoinButton?: boolean;
}

export const Header = ({ withJoinButton = false }: HeaderProps) => {
  const handleLoginClick = () => {
    window.open(loginLink, '_blank');
  };

  const handleMainLinkClick = () => {
    window.open(mainLink, '_blank');
  };

  const handleSignupClick = () => {
    window.open(signupLink, '_blank');
  };

  return (
    <header className="nj-header">
      <div className="nj-header-logo" onClick={handleMainLinkClick}>
        <img src={logoHorizontal} alt="Logo" />
      </div>

      <div className="nj-header-buttons">
        <button
          onClick={handleLoginClick}
          className="nj-button nj-button--secondary"
        >
          Log in
        </button>

        {withJoinButton && (
          <button
            onClick={handleSignupClick}
            className="nj-button nj-button--primary"
          >
            Join Beta
          </button>
        )}
      </div>
    </header>
  );
};
