import { useEffect, useState, useRef, useCallback } from 'react';
import { useGetSharedData } from 'src/hooks';
import { isNotFoundError } from 'src/store/services';
import { NotFoundPage } from 'src/components/NotFound';
import { SharedContent } from 'src/components/SharedContent';
import { Fab } from 'src/components/Fab';
import { ModalWithGallery } from 'src/components/ModalWithGallery';

export const ContentPage = () => {
  const { error } = useGetSharedData();
  const [contentError, setContentError] = useState<boolean>(false);
  const [showFab, setShowFab] = useState<boolean>(false);

  const contentPageRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    if (contentPageRef.current instanceof HTMLDivElement) {
      const x = contentPageRef.current.scrollHeight;
      contentPageRef.current.scrollTo({
        top: x,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    const currentRef = contentPageRef.current;

    const handleFabVisibility = () => {
      if (currentRef instanceof HTMLDivElement) {
        const { scrollTop, scrollHeight, clientHeight } = currentRef;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50;
        setShowFab(!isAtBottom);
      }
    };

    const handleResize = () => {
      if (currentRef instanceof HTMLDivElement) {
        const { scrollHeight, clientHeight } = currentRef;
        setShowFab(scrollHeight > clientHeight);
      }
    };

    currentRef?.addEventListener('scroll', handleFabVisibility);
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      currentRef?.removeEventListener('scroll', handleFabVisibility);
      window.removeEventListener('resize', handleResize);
    };
  }, [contentPageRef]);

  useEffect(() => {
    if (isNotFoundError(error)) {
      setContentError(true);
    }
  }, [error]);

  if (contentError) {
    return <NotFoundPage />;
  }

  return (
    <div className="nj-main--page-content" ref={contentPageRef}>
      <SharedContent />
      {showFab && <Fab onClick={scrollToBottom} />}
      <ModalWithGallery />
    </div>
  );
};
