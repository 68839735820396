/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DeepResearchOpenAiModel {
  GPT_4 = 'gpt-4',
  GPT_4_1106_PREVIEW = 'gpt-4-1106-preview',
}
