import { Footer } from '../Footer';
import { Icon } from '../Icon';
import { SVG_SIZE_XXXXL } from 'src/constants';

export const NotFoundPage = () => {
  return (
    <div className="nj-not-found-page">
      <Icon type="ninjaIconLight" size={SVG_SIZE_XXXXL} />
      <h1 className="nj-not-found-page--title">
        The link you are trying to access has expired or does not exist.
      </h1>
      <Footer />
    </div>
  );
};
