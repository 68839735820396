import { Check, Copy } from '@phosphor-icons/react';
import { toast } from 'react-toastify';

const SVG_SIZE = 24;

type CopyButtonProps = {
  text: string;
};

export const CopyButton = ({ text }: CopyButtonProps) => {
  const handleCopyClick = () => {
    navigator.clipboard.writeText(text);
    toast(
      <div className="nj-copy-snackbar-content">
        <Check size={24} />
        <span>Copied</span>
      </div>
    );
  };

  return (
    <button
      type="button"
      className="nj-modal-gallery-activity-button"
      onClick={(e) => {
        e.stopPropagation();
        handleCopyClick();
      }}
    >
      <Copy size={SVG_SIZE} />
    </button>
  );
};
