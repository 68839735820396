import Envelope from './envelope.svg';

type EnvelopeIconProps = {
  width?: number;
  height?: number;
};

export const EnvelopeIcon = ({
  width = 24,
  height = 24,
}: EnvelopeIconProps) => {
  return <img src={Envelope} width={width} height={height} alt="Email" />;
};
