import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useCollapse } from 'react-collapsed';
import { CalendarCardEvent } from 'src/types';
import { ScheduleEvent } from '../ScheduleEvent';
import { ScheduleExpandButton } from '../ScheduleExpandButton';
import { SCHEDULE_ACTION_CREATED } from 'src/constants';

interface ScheduleEventGroupProps {
  dateKeys: string[];
  activeDateKey: string;
  eventsByDate: Record<string, CalendarCardEvent[]>;
}

export const ScheduleEventGroup = ({
  dateKeys,
  activeDateKey,
  eventsByDate,
}: ScheduleEventGroupProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const sortedEventsByDate = useMemo(() => {
    return dateKeys.reduce((acc, date) => {
      const sortedEvents = eventsByDate[date]
        .slice()
        .sort((a, b) => dayjs(a.start).diff(dayjs(b.start)));
      acc[date] = sortedEvents;
      return acc;
    }, {} as Record<string, Array<CalendarCardEvent>>);
  }, [dateKeys, eventsByDate]);

  const currentEvents = useMemo(
    () => sortedEventsByDate[activeDateKey],
    [sortedEventsByDate, activeDateKey]
  );

  const extractEvents = useMemo(() => {
    const createdIndex = currentEvents.findIndex(
      (item) => item.action === SCHEDULE_ACTION_CREATED
    );

    if (createdIndex === -1) {
      return [];
    }

    const startIndex = Math.max(createdIndex - 2, 0);
    const endIndex = Math.min(createdIndex + 3, currentEvents.length);
    return currentEvents.slice(startIndex, endIndex);
  }, [currentEvents]);

  if (!currentEvents) {
    return null;
  }

  return (
    <div>
      <div>
        {(isExpanded
          ? currentEvents.slice(0, extractEvents.length)
          : extractEvents
        )?.map((item) => (
          <ScheduleEvent key={item.event_id} event={item} />
        ))}
      </div>

      <div {...getCollapseProps()}>
        {currentEvents.slice(extractEvents.length).map((item) => (
          <ScheduleEvent key={item.event_id} event={item} />
        ))}
      </div>

      {extractEvents.length < currentEvents.length && (
        <div {...getToggleProps()}>
          <ScheduleExpandButton isExpanded={isExpanded} />
        </div>
      )}
    </div>
  );
};
