import { Icon as PhosphorIconType } from '@phosphor-icons/react';
import { SVG_SIZE_XL } from 'src/constants';

interface ImageErrorDisplayProps {
  IconComponent: PhosphorIconType;
  iconSize?: number;
  className?: string;
  text?: string;
}

export const ImageErrorDisplay = ({
  IconComponent,
  iconSize = SVG_SIZE_XL,
  text,
}: ImageErrorDisplayProps) => (
  <>
    <IconComponent size={iconSize} />
    <span className="nj-broken-image-text">{text}</span>
  </>
);
