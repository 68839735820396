import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Header } from 'src/components/Header';
import { Footer } from 'src/components/Footer';

export const PageLayout = () => {
  return (
    <div className="nj-theme">
      <Header />

      <main className="nj-main">
        <Outlet />
      </main>

      <ToastContainer limit={3} style={{ width: 'auto' }} />

      <Footer />
    </div>
  );
};
