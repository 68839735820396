import { useParams } from 'react-router-dom';
import { useGetSharedDataQuery } from 'src/store/services';
import { sanitizeOutgoingContent } from 'src/utils';

export const useGetSharedData = () => {
  const { uuid = '' } = useParams();

  const sanitizedUuid = sanitizeOutgoingContent(uuid);

  return useGetSharedDataQuery(sanitizedUuid, { skip: !sanitizedUuid });
};
