import cn from 'classnames';
import { TabList, Tab } from 'src/v2/commonComponents/Tabs';
import { Icon } from 'src/components/Icon';
import { SUPER_AGENT_AS_EXTERNAL_MODEL, SVG_SIZE_M } from 'src/constants';
import { MessageTabData, MessageTabs } from 'src/types';
import styles from './SharedMessage.module.scss';

interface MessageTabListProps {
  data: MessageTabData[];
}

export const renderMessageTabList = ({ data }: MessageTabListProps) => {
  return (
    <TabList>
      {data.map(
        ({ tabName, iconName, IconComponent, tabTitle, tabTitleClassName }) => {
          const isSuperAgentTab =
            tabName.includes(SUPER_AGENT_AS_EXTERNAL_MODEL) ||
            tabName === MessageTabs.DEEP_RESEARCH;

          return (
            <Tab
              key={tabName}
              tab={tabName}
              className={cn(styles.tab, {
                [styles.highlighted]: isSuperAgentTab,
              })}
            >
              <span className={cn(styles.icon)}>
                {iconName ? (
                  <Icon type={iconName} size={SVG_SIZE_M} />
                ) : IconComponent ? (
                  <IconComponent size={SVG_SIZE_M} />
                ) : null}
              </span>

              <span className={tabTitleClassName}>{tabTitle}</span>
            </Tab>
          );
        },
      )}
    </TabList>
  );
};
