import { Icon } from 'src/components/Icon';

type NinjaLoaderProps = {
  content: string;
};

const IMG_SIZE = 160;

export const NinjaLoader = ({ content }: NinjaLoaderProps) => {
  return (
    <div className="nj-ninja-loader">
      <div className="nj-ninja-loader--images">
        <Icon type="ninjaIconLight" size={IMG_SIZE} alt="Ninja" />
        <div className="nj-ninja-loader--spinner"></div>
      </div>
      <div className="nj-ninja-loader--content">{content}</div>
    </div>
  );
};
