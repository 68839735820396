import { sanitize } from 'dompurify';
import { decodeHTML } from 'entities';

/**
 * @deprecated
 */
export const removeReferencesInBrackets = (content: string) => {
  return content.replace(/\[\d\]/g, '').replace(/,{2,}/gi, ',');
};

export const sanitizeOutgoingContent = (content: string) => {
  return sanitize(content);
};

export const sanitizeIncomingContent = (content: string) => {
  return decodeHTML(content);
};
