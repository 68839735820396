import { Check, Copy } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import { MessageType, SharedMessage as SharedMessageType } from 'src/types';
import {
  getCodeCopyTemplate,
  getResearchCopyTemplate,
  getSchedulerCopyTemplate,
} from 'src/utils';

type CopyMessageButtonProps = {
  item: SharedMessageType;
  disabled?: boolean;
};

const SVG_SIZE = 20;

export const CopyMessageButton = ({
  item,
  disabled,
}: CopyMessageButtonProps) => {
  const getCopyString = (item: SharedMessageType) => {
    switch (item.message_type) {
      case MessageType.CONVERSATION:
      case MessageType.TASK_CREATED:
        return (item.content ?? '').trim();
      case MessageType.CODE_TASK_CREATION_CARD:
        return getCodeCopyTemplate(item);
      case MessageType.RESEARCH_TASK_CREATION_CARD:
        return getResearchCopyTemplate(item);
      case MessageType.SCHEDULER_TASK_CREATION_CARD:
        return getSchedulerCopyTemplate(item);
      default:
        // since all cards will have content at least
        // providing a default case, otherwise, each
        // copyable card type should have a template.
        return (item.content ?? '').trim();
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getCopyString(item));
    toast(
      <div className="nj-copy-snackbar-content">
        <Check size={24} />
        <span>Copied</span>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        className: 'nj-copy-snackbar',
        autoClose: 1000,
        closeButton: false,
        hideProgressBar: true,
      }
    );
  };

  return (
    <button
      data-e2e="copy-message-button"
      onClick={handleCopy}
      disabled={disabled}
    >
      <Copy size={SVG_SIZE} color="currentColor" />
    </button>
  );
};
