import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  Citation,
  ModalGalleryType,
  isExternalModelReference,
} from 'src/types';

interface CitationLinkProps {
  references?: Citation[];
  citationIndex: number;
}

export const CitationLink = ({
  references = [],
  citationIndex,
}: CitationLinkProps) => {
  const { onChangeModalGalleryData } = useContext(SessionContext);

  if (references.length === 0) {
    return null;
  }

  const currentReference = references[citationIndex - 1];

  const url = currentReference?.url;

  const isDisabled =
    isExternalModelReference(currentReference) && !currentReference?.content;

  if (url?.includes('http')) {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={url}
        className="nj-citation-link"
      >
        {citationIndex}
      </a>
    );
  }

  const handleClick = () => {
    const modalType = ModalGalleryType.EXTERNAL_MODELS;

    onChangeModalGalleryData(true, modalType, references, citationIndex - 1);
  };

  return (
    <button
      disabled={isDisabled}
      onClick={handleClick}
      className="nj-citation-link"
    >
      {citationIndex}
    </button>
  );
};
