import {
  isString,
  isBoolean,
  isNumber,
  isObject,
  isNull,
} from './general.guard';
import {
  MessageChannel,
  ConversationRole,
  Conversation,
  MessageDebugData,
  ModelFeedback,
  ModelFeedbackDialogue,
  ApiTask,
  TaskChannel,
  TaskSkill,
  TaskState,
  SchedulingPayload,
  SchedulingRequest,
  Guest,
  Meeting,
  Negotiation,
  ReservationDetails,
  TestPayload,
  ApiUser,
  Message,
  UserSettings,
  UserSavingInsightsSettings,
  CurrencyAmount,
  Currency,
  UserCostSavingDuration,
  ResearchDataReferenceImage,
  ResearchDataReferenceVideo,
  ResearchData,
  ResearchDataReferenceWebPage,
  PDUMessage,
  FeedbackType,
  ResearchTaskPayload,
  ResearchTaskPayloadKind,
  ProgressUpdate,
  Action,
  ResearchDataReferenceCode,
  ResearchDataReferenceLLM,
  MessageType,
  OperationType,
  DebugMetadata,
  MessageMetadataResearchMetrics,
  ResearchResponseMetrics,
  MessageMetadataResearchReferenceCode,
  MessageMetadataResearchReferenceLLM,
  MessageMetadataResearchReferencesImageVideo,
  MessageMetadataResearchReferencesWebPage,
  MessageMetadataResearchStatusUpdate,
  MessageMetadataResearchSummary,
  MessageMetadataSchedulerMetrics,
  SchedulerCreateEventMetrics,
  CalendarCard,
  CalendarCardEvent,
  ChoicesCard,
  EmailCard,
  UserChoice,
  EmailCollaborator,
  ResearchCardData,
  ResearchSearchResult,
  AiResult,
  SearchWebPageReference,
  SearchImageReference,
  SearchVideoReference,
  AiReference,
  ResearchAi,
  ResearchCard,
  AiContent,
  WebSearchSettings,
  LlmSettings,
  LlmResource,
  UserResearchSettings,
  ChitChatCard,
} from './api';
import {
  AppUser,
  ApiContact,
  ApiTaskSelectable,
  MessageChunk,
  MessageFooter,
} from './api.extended';

import { ExternalModelReference, ImageGenerationItem } from '../types/general';
import { ImageCard } from 'src/types/models/ImageCard';

/**
 * isTaskStatus() tests data against TaskState data type.
 * @param data unknown
 * @returns boolean
 */
export function isTaskStatus(data: unknown): data is TaskState {
  const state = data as TaskState;

  if (!Object.values(TaskState).includes(state)) {
    return false;
  }

  return true;
}

/**
 * isCurrencyAmount() tests data against currency amount data type.
 * @param data unknown
 * @returns boolean
 */
export function isCurrencyAmount(data: unknown): data is CurrencyAmount {
  const amount = data as CurrencyAmount;

  if (!isObject(amount)) {
    return false;
  }

  if (
    !('currency' in amount && Object.values(Currency).includes(amount.currency))
  ) {
    return false;
  }

  if (!('amount_100x' in amount && isNumber(amount.amount_100x))) {
    return false;
  }

  return true;
}

/**
 * isUserSavingInsightsSettings() test data agaist UserSavingInsightsSettings data type.
 * @param data unknown
 * @returns boolean
 */
export function isUserSavingInsightsSettings(
  data: unknown
): data is UserSavingInsightsSettings {
  const settings = data as UserSavingInsightsSettings;

  if (
    'default_per_hour_cost' in settings &&
    !isCurrencyAmount(settings.default_per_hour_cost)
  ) {
    return false;
  }

  if (
    'default_duration' in settings &&
    !(
      settings.default_duration === undefined ||
      Object.values(UserCostSavingDuration).includes(settings.default_duration)
    )
  ) {
    return false;
  }

  return true;
}

/**
 * isLlmSettings() tests data against LlmSettings data type.
 * @param data unknown
 * @returns boolean
 */
export function isLlmSettings(data: unknown): data is LlmSettings {
  const llmSettings = data as LlmSettings;

  if ('llm_resources' in llmSettings) {
    if (!Array.isArray(llmSettings.llm_resources)) {
      return false;
    }

    for (const resource of llmSettings.llm_resources) {
      if (!Object.values(LlmResource).includes(resource)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * isUserResearchSettings() tests data against UserResearchSettings data type.
 * @param data unknown
 * @returns boolean
 */
export function isUserResearchSettings(
  data: unknown
): data is UserResearchSettings {
  const researchSettings = data as UserResearchSettings;

  if ('llms' in researchSettings) {
    if (!Array.isArray(researchSettings.llms)) {
      return false;
    }

    for (const llm of researchSettings.llms) {
      if (!isString(llm)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * isWebSearchSettings() tests data against WebSearchSettings data type.
 * @param data unknown
 * @returns boolean
 */
export function isWebSearchSettings(data: unknown): data is WebSearchSettings {
  const webSettings = data as WebSearchSettings;

  if ('search_images' in webSettings && !isBoolean(webSettings.search_images)) {
    return false;
  }

  if ('search_videos' in webSettings && !isBoolean(webSettings.search_videos)) {
    return false;
  }

  return true;
}

/**
 * isUserSettings() test data against user settings data type.
 * @param data unknown
 * @returns boolean
 */
export function isUserSettings(data: unknown): data is UserSettings {
  const settings = data as UserSettings;

  if (
    'cost_saving' in settings &&
    !isUserSavingInsightsSettings(settings.cost_saving)
  ) {
    return false;
  }

  if ('web_search' in settings && !isWebSearchSettings(settings.web_search)) {
    return false;
  }

  if ('llm' in settings && !isLlmSettings(settings.llm)) {
    return false;
  }

  if ('research' in settings && !isUserResearchSettings(settings.research)) {
    return false;
  }

  // att: scheduler is not used at FE just yet, we can omit it
  // if ('scheduler' in settings && !isUserSchedulerSettings(settings.scheduler)) {
  //  return false;
  // }

  return true;
}

/**
 * isApiUser() test data against ApiUser data type.
 * @param data unknown
 * @returns boolean
 */
export function isApiUser(data: unknown): data is ApiUser {
  const apiUser = data as ApiUser;

  if (!isObject(apiUser)) {
    return false;
  }

  if (!('user_id' in apiUser && isString(apiUser.user_id))) {
    return false;
  }

  if (!('email' in apiUser && isString(apiUser.email))) {
    return false;
  }

  if (!('company' in apiUser && isString(apiUser.company))) {
    return false;
  }

  if (!('first_name' in apiUser && isString(apiUser.first_name))) {
    return false;
  }

  if (!('last_name' in apiUser && isString(apiUser.last_name))) {
    return false;
  }

  if (!('user_type' in apiUser && isString(apiUser.user_type))) {
    return false;
  }

  // Attn: metadata is any in BE, metadata types to be added

  if ('cognito_sub' in apiUser && !isString(apiUser.cognito_sub)) {
    return false;
  }

  if ('conversation_id' in apiUser && !isString(apiUser.conversation_id)) {
    return false;
  }

  if ('settings' in apiUser && !isUserSettings(apiUser)) {
    return false;
  }

  if ('historical_conversation_ids' in apiUser) {
    if (!Array.isArray(apiUser.historical_conversation_ids)) {
      return false;
    }

    for (const historicalId of apiUser.historical_conversation_ids) {
      if (!isString(historicalId)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * isApiUserTypes() guards data to be an array of users.
 * @param data unknown
 * @returns boolean
 */
export function isApiUserList(data: unknown): data is Array<ApiUser> {
  const users = data as Array<ApiUser>;

  if (!Array.isArray(users)) {
    return false;
  }

  for (const user of users) {
    if (!isApiUser(user)) {
      return false;
    }
  }

  return true;
}

/**
 * isAppUser() test data type agaist AppUser data type.
 * @param data unknown
 * @returns boolean
 */
export function isAppUser(data: unknown): data is AppUser {
  const appUser = data as AppUser;

  if (!isApiUser(appUser)) {
    return false;
  }

  if ('avatar' in appUser && !isString(appUser.avatar)) {
    return false;
  }

  if ('role' in appUser && !isString(appUser.role)) {
    return false;
  }

  if ('color' in appUser && !isString(appUser.color)) {
    return false;
  }

  return true;
}

/**
 * isMessageDebugData() guards against debug data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageDebugData(data: unknown): data is MessageDebugData {
  const debug = data as MessageDebugData;

  if (!isObject(debug)) {
    return false;
  }

  if (
    'x_trace_id' in debug &&
    !(isString(debug.x_trace_id) || debug.x_trace_id === undefined)
  ) {
    return false;
  }

  return true;
}

/**
 * isModalFeedbackDialogue() guards against debug data type.
 * @param data unknown
 * @returns boolean
 */
export function isModelFeedbackDialogue(
  data: unknown
): data is ModelFeedbackDialogue {
  const dialogue = data as ModelFeedbackDialogue;

  if (!isObject(dialogue)) {
    return false;
  }

  if (!('content' in dialogue && isString(dialogue.content))) {
    return false;
  }

  if (!('role' in dialogue && isString(dialogue.role))) {
    return false;
  }

  return true;
}

/**
 * isModelFeedback() guards data to be of Feedback type.
 * @param data unknown
 * @returns boolean
 */
export function isModelFeedback(data: unknown): data is ModelFeedback {
  const feedback = data as ModelFeedback;

  if (isNull(feedback)) {
    return true;
  }

  if (!isObject(feedback)) {
    return false;
  }

  if (!('user_input' in feedback && isString(feedback.user_input))) {
    return false;
  }

  if (!('model_input' in feedback && isString(feedback.model_input))) {
    return false;
  }

  if (
    !('whole_dialogue' in feedback && Array.isArray(feedback.whole_dialogue))
  ) {
    return false;
  }

  for (const dialogue of feedback.whole_dialogue) {
    if (!isModelFeedbackDialogue(dialogue)) {
      return false;
    }
  }

  if (!('model_name' in feedback && isString(feedback.model_name))) {
    return false;
  }

  if (!('model_intent' in feedback && isString(feedback.model_intent))) {
    return false;
  }

  // Att: this portion is not working, DB records do not return this field.
  // if (!('ui_environment' in feedback && isString(feedback.ui_environment))) {
  //  return false;
  // }

  if (
    'is_intent_correct' in feedback &&
    !isBoolean(feedback.is_intent_correct)
  ) {
    return false;
  }

  if ('correct_intent' in feedback && !isString(feedback.correct_intent)) {
    return false;
  }

  if (!('model_response' in feedback && isString(feedback.model_response))) {
    return false;
  }

  if ('ideal_response' in feedback && !isString(feedback.ideal_response)) {
    return false;
  }

  if (
    'additional_feedback' in feedback &&
    !isString(feedback.additional_feedback)
  ) {
    return false;
  }

  if (!('user_name' in feedback && isString(feedback.user_name))) {
    return false;
  }

  if (!('timestamp' in feedback && isString(feedback.timestamp))) {
    return false;
  }

  if (!('request_id' in feedback && isString(feedback.request_id))) {
    return false;
  }

  if (!('model_id' in feedback && isString(feedback.model_id))) {
    return false;
  }

  if (!('model_output' in feedback && isString(feedback.model_output))) {
    return false;
  }

  if (!('thought' in feedback && isString(feedback.thought))) {
    return false;
  }

  if (!('label' in feedback && isString(feedback.label))) {
    return false;
  }

  if (!('input' in feedback && isString(feedback.input))) {
    return false;
  }

  if (!('reply' in feedback && isString(feedback.reply))) {
    return false;
  }

  if ('thumbs' in feedback && !isBoolean(feedback.thumbs)) {
    return false;
  }

  if ('understanding' in feedback && !isNumber(feedback.understanding)) {
    return false;
  }

  if ('helpfulness' in feedback && !isNumber(feedback.helpfulness)) {
    return false;
  }

  if ('coherence' in feedback && !isNumber(feedback.coherence)) {
    return false;
  }

  if ('completeness' in feedback && !isNumber(feedback.completeness)) {
    return false;
  }

  if ('accuracy' in feedback && !isNumber(feedback.accuracy)) {
    return false;
  }

  if ('language_quality' in feedback && !isNumber(feedback.language_quality)) {
    return false;
  }

  if ('relevance' in feedback && !isNumber(feedback.relevance)) {
    return false;
  }

  if ('user_experience' in feedback && !isNumber(feedback.user_experience)) {
    return false;
  }

  if ('style' in feedback && !isNumber(feedback.style)) {
    return false;
  }

  if ('error_handling' in feedback && !isNumber(feedback.error_handling)) {
    return false;
  }

  return true;
}

/**
 * isGuest() checks data against guest data type.
 * @param data unknown
 * @returns boolean
 */
export function isGuest(data: unknown): data is Guest {
  const guest = data as Guest;

  if (!isObject(guest)) {
    return false;
  }

  if ('email' in guest && !isString(guest.email)) {
    return false;
  }

  if ('name' in guest && !isString(guest.name)) {
    return false;
  }

  if ('role' in guest && !isString(guest.role)) {
    return false;
  }

  if ('accepted' in guest && !isBoolean(guest.accepted)) {
    return false;
  }

  if ('internal' in guest && !isBoolean(guest.internal)) {
    return false;
  }

  return true;
}

/**
 * isSchedulingRequest guards data for scheduling request type.
 * @param data unknown
 * @returns boolean
 */
export function isSchedulingRequest(data: unknown): data is SchedulingRequest {
  const request = data as SchedulingRequest;

  if (!isObject(request)) {
    return false;
  }

  if ('content' in request && !isString(request.content)) {
    return false;
  }

  if (
    'guests' in request &&
    !(request.guests === undefined || Array.isArray(request.guests))
  ) {
    return false;
  }

  if (request.guests) {
    for (const guest of request.guests) {
      if (!isGuest(guest)) {
        return false;
      }
    }
  }

  if ('topic' in request && !isString(request.topic)) {
    return false;
  }

  if ('proposed_date' in request && !isString(request.proposed_date)) {
    return false;
  }

  if ('proposed_time' in request && !isString(request.proposed_time)) {
    return false;
  }

  if ('proposed_duration' in request && !isString(request.proposed_duration)) {
    return false;
  }

  if ('request_timestamp' in request && !isString(request.request_timestamp)) {
    return false;
  }

  return false;
}

/**
 * isMeeting() tests data against Meeting data type.
 * @param data unknown
 * @returns boolean
 */
export function isMeeting(data: unknown): data is Meeting {
  const meeting = data as Meeting;

  if (!isObject(meeting)) {
    return false;
  }

  if (!('organizer' in meeting && isGuest(meeting.organizer))) {
    return false;
  }

  if (!('guests' in meeting && Array.isArray(meeting.guests))) {
    return false;
  }

  for (const guest of meeting.guests) {
    if (!isGuest(guest)) {
      return false;
    }
  }

  if (!('start_datetime' in meeting && isString(meeting.start_datetime))) {
    return false;
  }

  if (!('end_datetime' in meeting && isString(meeting.end_datetime))) {
    return false;
  }

  if (!('subject' in meeting && isString(meeting.subject))) {
    return false;
  }

  if ('location' in meeting && !isString(meeting.location)) {
    return false;
  }

  if ('description' in meeting && !isString(meeting.description)) {
    return false;
  }

  if (!('duration' in meeting && isNumber(meeting.duration))) {
    return false;
  }

  return true;
}

/**
 * isNegotiation() tests data against Negotiation data type.
 * @param data unknown
 * @returns boolean
 */
export function isNegotiation(data: unknown): data is Negotiation {
  const negotiation = data as Negotiation;

  if (!isObject(negotiation)) {
    return false;
  }

  if (
    !(
      'proposed_start_datetime' in negotiation &&
      isString(negotiation.proposed_start_datetime)
    )
  ) {
    return false;
  }

  if (
    !(
      'proposed_end_datetime' in negotiation &&
      isString(negotiation.proposed_end_datetime)
    )
  ) {
    return false;
  }

  if (
    !(
      'proposed_duration' in negotiation &&
      isNumber(negotiation.proposed_duration)
    )
  ) {
    return false;
  }

  if (!('guests' in negotiation && Array.isArray(negotiation.guests))) {
    return false;
  }

  for (const guest in negotiation.guests) {
    if (!isGuest(guest)) {
      return false;
    }
  }

  if ('subject' in negotiation && !isString(negotiation.subject)) {
    return false;
  }

  return true;
}

/**
 * isSchedulingPayload() guards data to be of scheduling payload.
 * @param data unknown
 * @returns boolean
 */
export function isSchedulingPayload(data: unknown): data is SchedulingPayload {
  const payload = data as SchedulingPayload;

  if (!isObject(payload)) {
    return false;
  }

  if ('payload_type' in payload && !isString(payload.payload_type)) {
    return false;
  }

  if ('request' in payload && isSchedulingRequest(payload.request)) {
    return false;
  }

  if ('meeting' in payload && !isMeeting(payload.meeting)) {
    return false;
  }

  if ('negotiation' in payload && !isNegotiation(payload.negotiation)) {
    return false;
  }

  return true;
}

/**
 * isReservationDetails() tests data against resevration details.
 * @param data unknown
 * @returns boolean
 */
export function isReservationDetails(
  data: unknown
): data is ReservationDetails {
  const reservation = data as ReservationDetails;

  if (!isObject(reservation)) {
    return false;
  }

  if (!('payload_type' in reservation && isString(reservation.payload_type))) {
    return false;
  }

  if (
    !(
      'time_range_minutes' in reservation &&
      isNumber(reservation.time_range_minutes)
    )
  ) {
    return false;
  }

  if (!('phone_number' in reservation && isString(reservation.phone_number))) {
    return false;
  }

  if (
    !(
      'number_of_people' in reservation &&
      isNumber(reservation.number_of_people)
    )
  ) {
    return false;
  }

  if ('start_time' in reservation && !isString(reservation.start_time)) {
    return false;
  }

  if (
    'extra_requirements' in reservation &&
    !isString(reservation.extra_requirements)
  ) {
    return false;
  }

  return true;
}

/**
 * isResearchTaskPayload() tests data to be of ResearchTaskPayload type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchTaskPayload(
  data: unknown
): data is ResearchTaskPayload {
  const payload = data as ResearchTaskPayload;

  if ('payload_type' in payload && !isString(payload.payload_type)) {
    return false;
  }

  if ('query' in payload && !isString(payload.query)) {
    return false;
  }

  if (
    'kind' in payload &&
    !Object.values(ResearchTaskPayloadKind).includes(payload.kind)
  ) {
    return false;
  }

  return true;
}

/**
 * isTestPayload() tests data against TestPayload data type.
 * @param data unknown
 * @returns boolean
 */
export function isTestPayload(data: unknown): data is TestPayload {
  const test = data as TestPayload;

  if (!isObject(test)) {
    return false;
  }

  if (!('payload_type' in test && isString(test.payload_type))) {
    return false;
  }

  if ('content' in test && !isString(test.content)) {
    return false;
  }

  return true;
}

/**
 * isApiTaskSelectable() guards tasks against ApiTaskSelectable data type.
 * @param data unknown
 * @returns boolean
 */
export function isApiTaskSelectable(data: unknown): data is ApiTaskSelectable {
  const task = data as ApiTaskSelectable;

  if (!isApiTask(task)) {
    return false;
  }

  if ('selected' in task && !isBoolean(task.selected)) {
    return false;
  }

  if ('collaborators' in task) {
    if (!Array.isArray(task.collaborators)) {
      return false;
    }

    for (const person of task.collaborators) {
      if (!isString(person)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * isPartialApiTask() tests data to be a partial task with optional data.
 * @param data unknown
 * @returns boolean
 */
export function isPartialApiTask(data: unknown): data is Partial<ApiTask> {
  const task = data as ApiTask;

  if (!isObject(task)) {
    return false;
  }

  if ('task_id' in task && !isString(task.task_id)) {
    return false;
  }

  if ('user_id' in task && !isString(task.user_id)) {
    return false;
  }

  if ('agent_id' in task && !isString(task.agent_id)) {
    return false;
  }

  if ('task_subject' in task && !isString(task.task_subject)) {
    return false;
  }

  if ('task_hash' in task && !isString(task.task_hash)) {
    return false;
  }

  if (
    'channel' in task &&
    !(
      isString(task.channel) &&
      Object.values(TaskChannel).includes(task.channel)
    )
  ) {
    return false;
  }

  if (
    'state' in task &&
    !(isString(task.state) && Object.values(TaskState).includes(task.state))
  ) {
    return false;
  }

  if (
    'skill' in task &&
    !(isString(task.skill) && Object.values(TaskSkill).includes(task.skill))
  ) {
    return false;
  }

  if ('requires_attention' in task && !isBoolean(task.requires_attention)) {
    return false;
  }

  if ('conversation_id' in task && !isString(task.conversation_id)) {
    return false;
  }

  if (
    'payload' in task &&
    (isSchedulingPayload(task.payload) ||
      isReservationDetails(task.payload) ||
      isResearchTaskPayload(task.payload) ||
      isTestPayload(task.payload))
  ) {
    return false;
  }

  if (
    'conversation' in task &&
    !(task.conversation === undefined || isConversation(task.conversation))
  ) {
    return false;
  }

  if ('created_at' in task && !isString(task.created_at)) {
    return false;
  }

  if ('updated_at' in task && !isString(task.updated_at)) {
    return false;
  }

  if (
    'feedback_type' in task &&
    !(
      task.feedback_type === undefined ||
      Object.values(FeedbackType).includes(task.feedback_type)
    )
  ) {
    return false;
  }

  return true;
}

/**
 * isApiTask() guards data to be of chat task type.
 * @param data unknown
 * @returns boolean
 */
export function isApiTask(data: unknown): data is ApiTask {
  const task = data as ApiTask;

  if (!isPartialApiTask(task)) {
    return false;
  }

  if (
    !(
      'user_id' in task &&
      'channel' in task &&
      'skill' in task &&
      'agent_id' in task
    )
  ) {
    return false;
  }

  if ('selected' in task && !isBoolean(task.selected)) {
    return false;
  }

  if ('collaborators' in task && !Array.isArray(task.collaborators)) {
    return false;
  }

  return true;
}

/**
 * ResearchDataReferenceImage() tests data against ResearchDataReferenceImage data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchDataReferenceImage(
  data: unknown
): data is ResearchDataReferenceImage {
  const researchImage = data as ResearchDataReferenceImage;

  if (!isObject(researchImage)) {
    return false;
  }

  if (!('url' in researchImage && isString(researchImage.url))) {
    return false;
  }

  if ('kind' in researchImage && researchImage.kind !== 'image') {
    return false;
  }

  if (!('source' in researchImage && isString(researchImage.source))) {
    return false;
  }

  if (!('title' in researchImage && isString(researchImage.title))) {
    return false;
  }

  if (!('height' in researchImage && isString(researchImage.height))) {
    return false;
  }

  if (!('width' in researchImage && isString(researchImage.width))) {
    return false;
  }

  return true;
}

/**
 * isResearchDataReferenceVideo() tests data against ResearchDataReferenceVideo data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchDataReferenceVideo(
  data: unknown
): data is ResearchDataReferenceVideo {
  const researchVideo = data as ResearchDataReferenceVideo;

  if (!isObject(researchVideo)) {
    return false;
  }

  if (!('url' in researchVideo && isString(researchVideo.url))) {
    return false;
  }

  if ('kind' in researchVideo && researchVideo.kind !== 'video') {
    return false;
  }

  if (
    !('displayed_url' in researchVideo && isString(researchVideo.displayed_url))
  ) {
    return false;
  }

  if (!('duration' in researchVideo && isString(researchVideo.duration))) {
    return false;
  }

  if (!('snippet' in researchVideo && isString(researchVideo.snippet))) {
    return false;
  }

  if (!('thumbnail' in researchVideo && isString(researchVideo.thumbnail))) {
    return false;
  }

  if (!('title' in researchVideo && isString(researchVideo.title))) {
    return false;
  }

  return true;
}

/**
 * isResearchDataReferenceWebPage() tests data against ResearchDataReferenceWebPage data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchDataReferenceWebPage(
  data: unknown
): data is ResearchDataReferenceWebPage {
  const researchWebPage = data as ResearchDataReferenceWebPage;

  if (!isObject(researchWebPage)) {
    return false;
  }

  if (
    !(
      'kind' in researchWebPage &&
      isString(researchWebPage.kind) &&
      researchWebPage.kind === 'web_page'
    )
  ) {
    return false;
  }

  if (!('snippet' in researchWebPage && isString(researchWebPage.snippet))) {
    return false;
  }

  if (!('title' in researchWebPage && isString(researchWebPage.title))) {
    return false;
  }

  if (!('url' in researchWebPage && isString(researchWebPage.url))) {
    return false;
  }

  if (
    'favicon_url' in researchWebPage &&
    !isString(researchWebPage.favicon_url)
  ) {
    return false;
  }

  if (
    'website_title' in researchWebPage &&
    !isString(researchWebPage.website_title)
  ) {
    return false;
  }

  if (
    'website_domain' in researchWebPage &&
    !isString(researchWebPage.website_domain)
  ) {
    return false;
  }

  return true;
}

/**
 * isResearchDataReferenceCode() guards data against ResearchDataReferenceCode type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchDataReferenceCode(
  data: unknown
): data is ResearchDataReferenceCode {
  const researchCode = data as ResearchDataReferenceCode;

  if (!isObject(researchCode)) {
    return false;
  }

  if (
    'kind' in researchCode &&
    !(isString(researchCode.kind) && researchCode.kind === 'code')
  ) {
    return false;
  }

  if (!('content' in researchCode && isString(researchCode.content))) {
    return false;
  }

  if (!('source' in researchCode && isString(researchCode.content))) {
    return false;
  }

  return true;
}

/**
 * isResearchDataReferenceLLM() type guards data against ResearchDataReferenceLLM.
 * @param data unknonwn
 * @returns boolean
 */
export function isResearchDataReferenceLLM(
  data: unknown
): data is ResearchDataReferenceLLM {
  const researchLLM = data as ResearchDataReferenceLLM;

  if (!isObject(researchLLM)) {
    return false;
  }

  if (
    'kind' in researchLLM &&
    !(isString(researchLLM.kind) && researchLLM.kind === 'llm')
  ) {
    return false;
  }

  if (!('content' in researchLLM && isString(researchLLM.content))) {
    return false;
  }

  if (!('source' in researchLLM && isString(researchLLM.source))) {
    return false;
  }

  return true;
}

/**
 * isResearchData() tests data against ResearchData data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchData(data: unknown): data is ResearchData {
  const research = data as ResearchData;

  if (!isObject(research)) {
    return false;
  }

  if (!('payload_type' in research && isString(research.payload_type))) {
    return false;
  }

  if (!('query' in research && isString(research.query))) {
    return false;
  }

  if (!('summary' in research && isString(research.summary))) {
    return false;
  }

  if (!('references' in research && Array.isArray(research.references))) {
    return false;
  }

  for (const reference of research.references) {
    if (
      !(
        isResearchDataReferenceLLM(reference) ||
        isResearchDataReferenceWebPage(reference) ||
        isResearchDataReferenceVideo(reference) ||
        isResearchDataReferenceImage(reference) ||
        isResearchDataReferenceCode(reference)
      )
    ) {
      return false;
    }
  }

  return true;
}

export function isChitChatCard(data: unknown): data is ChitChatCard {
  const chitChatCard = data as ChitChatCard;

  if (!isObject(chitChatCard)) {
    return false;
  }

  if (
    'payload_type' in chitChatCard &&
    chitChatCard.payload_type !== 'chit-chat-card'
  ) {
    return false;
  }

  return true;
}

export function isImageGenerationCard(data: unknown): data is ImageCard {
  const imageCard = data as ImageCard;

  if (!isObject(imageCard)) {
    return false;
  }

  if ('payload_type' in imageCard && imageCard.payload_type !== 'image-card') {
    return false;
  }

  return true;
}

export function isImageGeneratedItem(
  data: unknown
): data is ImageGenerationItem {
  const imageGenerationItem = data;

  if (!isObject(imageGenerationItem)) {
    return false;
  }

  if (imageGenerationItem === null) {
    return false;
  }

  if (!('url' in imageGenerationItem)) {
    return false;
  }

  if (!('title' in imageGenerationItem)) {
    return false;
  }

  if (!('icon' in imageGenerationItem)) {
    return false;
  }

  return true;
}

/**
 * isAction() tests data against progress update type.
 * @param data unknown
 * @returns boolean
 */
export function isAction(data: unknown): data is Action {
  const actionData = data as Action;

  if ('action' in actionData && !isString(actionData.action)) {
    return false;
  }

  if ('status' in actionData && !isString(actionData.action)) {
    return false;
  }

  return true;
}

/**
 * isProgressUpdate() tests data against progress update type.
 * @param data unknown
 * @returns boolean
 */
export function isProgressUpdate(data: unknown): data is ProgressUpdate {
  const progress = data as ProgressUpdate;

  // todo: update as object refines
  if (
    'payload_type' in progress &&
    progress.payload_type !== 'progress-update'
  ) {
    return false;
  }

  if ('actions' in progress) {
    if (!Array.isArray(progress.actions)) {
      return false;
    }

    for (const action of progress.actions) {
      if (!isAction(action)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * isMessage() checks data against Message type.
 * @param data unknown
 * @returns boolean
 */
export function isMessage(data: unknown): data is Message {
  const message = data as Message;

  if (!isObject(message)) {
    return false;
  }

  if ('message_id' in message && !isString(message.message_id)) {
    return false;
  }

  if ('conversation_id' in message && !isString(message.conversation_id)) {
    return false;
  }

  if ('timestamp' in message && !isString(message.timestamp)) {
    return false;
  }

  if (!('user_id' in message && isString(message.user_id))) {
    return false;
  }

  if (!('from_user_id' in message && isString(message.from_user_id))) {
    return false;
  }

  if (!('to_user_id' in message && isString(message.to_user_id))) {
    return false;
  }

  if (
    'channel' in message &&
    !(
      isString(message.channel) &&
      Object.values(MessageChannel).includes(message.channel)
    )
  ) {
    return false;
  }

  if (
    !(
      'role' in message &&
      isString(message.role) &&
      Object.values(ConversationRole).includes(message.role)
    )
  ) {
    return false;
  }

  if (!('content' in message && isString(message.content))) {
    return false;
  }

  if (
    'response_metadata' in message &&
    message.response_metadata !== null &&
    !(
      isResearchData(message.response_metadata) ||
      isTestPayload(message.response_metadata)
    )
  ) {
    return false;
  }

  if (
    'message_type' in message &&
    !(
      isString(message.message_type) &&
      Object.values(MessageType).includes(message.message_type as MessageType)
    )
  ) {
    return false;
  }

  if (
    'operation_type' in message &&
    !(
      isString(message.operation_type) &&
      Object.values(OperationType).includes(message.operation_type)
    )
  ) {
    return false;
  }

  if (
    'payload' in message &&
    !(
      isProgressUpdate(message.payload) ||
      isCalendarCard(message.payload) ||
      isEmailCard(message.payload) ||
      isChoicesCard(message.payload) ||
      isTestPayload(message.payload) ||
      isResearchData(message.payload) ||
      isResearchCard(message.payload)
    )
  ) {
    return false;
  }

  if (
    'metadata' in message &&
    !(
      isDebugMetadata(message.metadata) ||
      isTestPayload(message.metadata) ||
      isMessageMetadataResearchMetrics(message.metadata) ||
      isMessageMetadataResearchReferenceCode(message.metadata) ||
      isMessageMetadataResearchReferenceLLM(message.metadata) ||
      isMessageMetadataResearchReferencesImageVideo(message.metadata) ||
      isMessageMetadataResearchReferencesWebPage(message.metadata) ||
      isMessageMetadataResearchStatusUpdate(message.metadata) ||
      isMessageMetadataResearchSummary(message.metadata) ||
      isMessageMetadataSchedulerMetrics(message.metadata)
    )
  ) {
    return false;
  }

  if ('is_read' in message && !isBoolean(message.is_read)) {
    return false;
  }

  if ('is_final_answer' in message && !isBoolean(message.is_final_answer)) {
    return false;
  }

  if ('task_id' in message && !isString(message.task_id)) {
    return false;
  }

  if ('tag' in message && !isString(message.tag)) {
    return false;
  }

  if (
    'feedback_type' in message &&
    !(
      message.feedback_type === undefined ||
      Object.values(FeedbackType).includes(message.feedback_type)
    )
  ) {
    return false;
  }

  return true;
}

/**
 * isMessageMetadataResearchReferencesWebPage() tests data against
 * MessageMetadataResearchReferencesWebPage data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchReferencesWebPage(
  data: unknown
): data is MessageMetadataResearchReferencesWebPage {
  const webpage = data as MessageMetadataResearchReferencesWebPage;

  if (
    'payload_type' in webpage &&
    webpage.payload_type !== 'research-references-web-page'
  ) {
    return false;
  }

  if (!('data' in webpage && Array.isArray(webpage.data))) {
    return false;
  }

  for (const page of webpage.data) {
    if (!isResearchDataReferenceWebPage(page)) {
      return false;
    }
  }

  return true;
}

/**
 * isMessageMetadataResearchStatusUpdate()
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchStatusUpdate(
  data: unknown
): data is MessageMetadataResearchStatusUpdate {
  const update = data as MessageMetadataResearchStatusUpdate;

  if (
    'payload_type' in update &&
    update.payload_type !== 'research-status-update'
  ) {
    return false;
  }

  if (!('data' in update && isString(update.data))) {
    return false;
  }

  return true;
}

/**
 * isMessageMetadataResearchSummary()
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchSummary(
  data: unknown
): data is MessageMetadataResearchSummary {
  const summary = data as MessageMetadataResearchSummary;

  if (
    'payload_type' in summary &&
    summary.payload_type !== 'research-summary'
  ) {
    return false;
  }

  if (!('data' in summary && isString(summary.data))) {
    return false;
  }

  return true;
}

/**
 * isMessageMetadataSchedulerMetrics() test data against
 * MessageMetadataSchedulerMetrics data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataSchedulerMetrics(
  data: unknown
): data is MessageMetadataSchedulerMetrics {
  const metrics = data as MessageMetadataSchedulerMetrics;

  if (
    'payload_type' in metrics &&
    metrics.payload_type !== 'scheduler-metrics'
  ) {
    return false;
  }

  if (!('data' in metrics && isSchedulerCreateEventMetrics(metrics.data))) {
    return false;
  }

  return true;
}

/**
 * isSchedulerCreateEventMetrics() test data against
 * SchedulerCreateEventMetrics data type.
 * @param data unknown
 * @returns boolean
 */
export function isSchedulerCreateEventMetrics(
  data: unknown
): data is SchedulerCreateEventMetrics {
  const scheduler = data as SchedulerCreateEventMetrics;

  if (!('num_attendees' in scheduler && isNumber(scheduler.num_attendees))) {
    return false;
  }

  return true;
}

/**
 * isMessageMetadataResearchReferencesImageVideo() test data against
 * MessageMetadataResearchReferencesImageVideo data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchReferencesImageVideo(
  data: unknown
): data is MessageMetadataResearchReferencesImageVideo {
  const imageVideo = data as MessageMetadataResearchReferencesImageVideo;

  if (
    'payload_type' in imageVideo &&
    imageVideo.payload_type !== 'research-references-image-video'
  ) {
    return false;
  }

  if (!('data' in imageVideo && Array.isArray(imageVideo.data))) {
    return false;
  }

  for (const media of imageVideo.data) {
    if (
      !(
        isResearchDataReferenceImage(media) ||
        isResearchDataReferenceVideo(media)
      )
    ) {
      return false;
    }
  }

  return true;
}

/**
 * isMessageMetadataResearchReferenceLLM() tests against
 * MessageMetadataResearchReferenceLLM data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchReferenceLLM(
  data: unknown
): data is MessageMetadataResearchReferenceLLM {
  const llm = data as MessageMetadataResearchReferenceLLM;

  if (
    !('payload_type' in llm && llm.payload_type !== 'research-reference-llm')
  ) {
    return false;
  }

  if (!('data' in llm && isResearchDataReferenceLLM(llm.data))) {
    return false;
  }

  return true;
}

/**
 * isMessageMetadataResearchReferenceCode() test against
 * MessageMetadataResearchReferenceCode data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchReferenceCode(
  data: unknown
): data is MessageMetadataResearchReferenceCode {
  const code = data as MessageMetadataResearchReferenceCode;

  if (
    'payload_type' in code &&
    code.payload_type !== 'research-reference-code'
  ) {
    return false;
  }

  if (!('data' in code && isResearchDataReferenceCode(code.data))) {
    return false;
  }

  return true;
}

/**
 * isMessageMetadataResearchMetric() test data against
 * MessageMetadataResearchMetric type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchMetrics(
  data: unknown
): data is MessageMetadataResearchMetrics {
  const metadataMetric = data as MessageMetadataResearchMetrics;

  if (
    'payload_type' in metadataMetric &&
    metadataMetric.payload_type !== 'research-metrics'
  ) {
    return false;
  }

  if (
    !(
      'data' in metadataMetric && isResearchResponseMetrics(metadataMetric.data)
    )
  ) {
    return false;
  }

  return true;
}

/**
 * isResearchResponseMetrics() test data against ResearchResponseMetrics.
 * @param data unknown
 * @returns boolean
 */
export function isResearchResponseMetrics(
  data: unknown
): data is ResearchResponseMetrics {
  const metrics = data as ResearchResponseMetrics;

  if (
    !('words_written_count' in metrics && isNumber(metrics.words_written_count))
  ) {
    return false;
  }

  if (!('words_read_count' in metrics && isNumber(metrics.words_read_count))) {
    return false;
  }

  return true;
}

/**
 * isDebugMetadata() test data against DebugMetadata type.
 * @param data unknown
 * @returns boolean
 */
export function isDebugMetadata(data: unknown): data is DebugMetadata {
  const metadata = data as DebugMetadata;

  if (
    'payload_type' in metadata &&
    metadata.payload_type !== 'debug-metadata'
  ) {
    return false;
  }

  if (!('intent_label' in metadata && isString(metadata.intent_label))) {
    return false;
  }

  return true;
}

/**
 * isConversation() checks data against Conversation data type.
 * @param data unknown
 * @returns boolean
 */
export function isConversation(data: unknown): data is Conversation {
  const conversation = data as Conversation;

  if (!isObject(conversation)) {
    return false;
  }

  if (
    'conversation_id' in conversation &&
    !isString(conversation.conversation_id)
  ) {
    return false;
  }

  if (!('user_id' in conversation && isString(conversation.user_id))) {
    return false;
  }

  if ('timestamp' in conversation && !isString(conversation.timestamp)) {
    return false;
  }

  if ('messages' in conversation && Array.isArray(conversation.messages)) {
    for (const msg of conversation.messages) {
      if (!isMessage(msg)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * isApiContact() tests data against ApiContact data type.
 * @param data unknown
 * @returns boolean
 */
export function isApiContact(data: unknown): data is ApiContact {
  const contact = data as ApiContact;

  if (!('email' in contact && isString(contact.email))) {
    return false;
  }

  if (!('first_name' in contact && isString(contact.first_name))) {
    return false;
  }

  if ('last_name' in contact && !isString(contact.last_name)) {
    return false;
  }

  if ('color' in contact && !isString(contact.color)) {
    return false;
  }

  if ('avatar' in contact && !isString(contact.avatar)) {
    return false;
  }

  return true;
}

/**
 * isPDUMessage() type guards for combus objects.
 * @param data unknown
 * @returns boolean
 */
export function isPDUMessage(data: unknown): data is PDUMessage {
  const response = data as PDUMessage;

  if (!isObject(response)) {
    return false;
  }

  if (!('user_id' in response && isString(response.user_id))) {
    return false;
  }

  if (!('event_type' in response && isString(response.event_type))) {
    return false;
  }

  if (!('channel' in response && Array.isArray(response.channel))) {
    return false;
  }

  for (const channel of response.channel) {
    if (!Object.values(MessageChannel).includes(channel)) {
      return false;
    }
  }

  if ('timestamp' in response && !isString(response.timestamp)) {
    return false;
  }

  if (!('payload' in response && isString(response.payload))) {
    return false;
  }

  return true;
}

/**
 * isMessageChunk() checks the format of the chunk and the type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageChunk(data: unknown): data is MessageChunk {
  const chunk = data as MessageChunk;

  if (!('message_id' in chunk && isString(chunk.message_id))) {
    return false;
  }

  if (!('content' in chunk && isString(chunk.content))) {
    return false;
  }

  return true;
}

/**
 * isMessageFooter() checks the format of the message footer type.
 * @param data unknown
 * @return boolean
 */
export function isMessageFooter(data: unknown): data is MessageFooter {
  const footer = data as MessageFooter;

  if (!('message_id' in footer && isString(footer.message_id))) {
    return false;
  }

  return true;
}

/**
 * isUserChoice() tests data against UserChoice data type.
 * @param data unknown
 * @returns boolean
 */
export function isUserChoice(data: unknown): data is UserChoice {
  const userChoice = data as UserChoice;

  if (!isObject(userChoice)) {
    return false;
  }

  if ('title' in userChoice && !isString(userChoice.title)) {
    return false;
  }

  if ('description' in userChoice && !isString(userChoice.description)) {
    return false;
  }

  if ('link' in userChoice && !isString(userChoice.link)) {
    return false;
  }

  if ('sub_options' in userChoice) {
    if (!Array.isArray(userChoice.sub_options)) {
      return false;
    }

    // att: uncomment when we have sub_options coming in
    // for (const subOption of userChoice.sub_options) {
    //  if (!isUserChoice(subOption)) {
    //    return false;
    //  }
    // }
  }

  if ('selected' in userChoice && !isBoolean(userChoice.selected)) {
    return false;
  }

  return true;
}

/**
 * isChoicesCard() tests data against ChoicesCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isChoicesCard(data: unknown): data is ChoicesCard {
  const choicesCard = data as ChoicesCard;

  if (!('version' in choicesCard && isString(choicesCard.version))) {
    return false;
  }

  if ('payload_type' in choicesCard && !isString(choicesCard.payload_type)) {
    return false;
  }

  if ('description' in choicesCard && !isString(choicesCard.description)) {
    return false;
  }

  if (!('options' in choicesCard && Array.isArray(choicesCard.options))) {
    return false;
  }

  for (const option in choicesCard.options) {
    if (!isUserChoice(option)) {
      return false;
    }
  }

  if ('executed' in choicesCard && !isBoolean(choicesCard.executed)) {
    return false;
  }

  return true;
}

/**
 * isCalendarCard() tests data against CalendarCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isCalendarCard(data: unknown): data is CalendarCard {
  const calendar = data as CalendarCard;

  if ('payload_type' in calendar && calendar.payload_type !== 'calendar-card') {
    return false;
  }

  if ('dates' in calendar && !isObject(calendar.dates)) {
    return false;
  }

  for (const value of Object.values(calendar.dates)) {
    if (!isCalendarCardEvent(value)) {
      return false;
    }
  }

  return true;
}

/**
 * isCalendarCardEvent() tests data
 * @param data unknown
 * @returns boolean
 */
export function isCalendarCardEvent(data: unknown): data is CalendarCardEvent {
  const calendarEvent = data as CalendarCardEvent;

  if (!('event_id' in calendarEvent && isString(calendarEvent.event_id))) {
    return false;
  }

  if (!('title' in calendarEvent && isString(calendarEvent.title))) {
    return false;
  }

  if (!('start' in calendarEvent && isString(calendarEvent.start))) {
    return false;
  }

  if (!('end' in calendarEvent && isString(calendarEvent.end))) {
    return false;
  }

  if ('action' in calendarEvent && !isString(calendarEvent.action)) {
    return false;
  }

  if (
    'is_recurring' in calendarEvent &&
    !isBoolean(calendarEvent.is_recurring)
  ) {
    return false;
  }

  return true;
}

/**
 * isEmailCollaborator() tests data against EmailCollaborator/EmailAddress data type.
 * We import EmailAddress as EmailCollaborator due to clash with EmailAddress from AWS types.
 * @param data unknown
 * @returns boolean
 */
export function isEmailCollaborator(data: unknown): data is EmailCollaborator {
  const collaborator = data as EmailCollaborator;

  if (!isObject(collaborator)) {
    return false;
  }

  if (
    !('email_address' in collaborator && isString(collaborator.email_address))
  ) {
    return false;
  }

  if ('contact_uri' in collaborator && !isString(collaborator.contact_uri)) {
    return false;
  }

  if (
    !('display_name' in collaborator && isString(collaborator.display_name))
  ) {
    return false;
  }

  return true;
}

/**
 * isEmailCard() tests data against EmailCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isEmailCard(data: unknown): data is EmailCard {
  const emailCard = data as EmailCard;

  if (!isObject(emailCard)) {
    return false;
  }

  if (!('version' in emailCard && isString(emailCard.version))) {
    return false;
  }

  if ('payload_type' in emailCard && emailCard.payload_type !== 'email-card') {
    return false;
  }

  if ('sender' in emailCard && !isEmailCollaborator(emailCard.sender)) {
    return false;
  }

  return false;
}

/**
 * isSearchWebPageReference() test data against SearchWebPageReference data type.
 * @param data unknown
 * @returns boolean
 */
export function isSearchWebPageReference(
  data: unknown
): data is SearchWebPageReference {
  const webReference = data as SearchWebPageReference;

  if (!('url' in webReference && isString(webReference.url))) {
    return false;
  }

  if (!('title' in webReference && isString(webReference.title))) {
    return false;
  }

  if (!('snippet' in webReference && isString(webReference.snippet))) {
    return false;
  }

  if ('favicon' in webReference && !isString(webReference.favicon)) {
    return false;
  }

  if ('source' in webReference && !isString(webReference.source)) {
    return false;
  }

  if ('domain' in webReference && !isString(webReference.domain)) {
    return false;
  }

  return true;
}

/**
 * isSearchImageReference() test data against SearchImageReference data type.
 * @param data unknown
 * @returns boolean
 */
export function isSearchImageReference(
  data: unknown
): data is SearchImageReference {
  const imageReference = data as SearchImageReference;

  if (!('url' in imageReference && isString(imageReference.url))) {
    return false;
  }

  if (!('title' in imageReference && isString(imageReference.title))) {
    return false;
  }

  if (!('height' in imageReference && isNumber(imageReference.height))) {
    return false;
  }

  if (!('width' in imageReference && isNumber(imageReference.width))) {
    return false;
  }

  if (!('source' in imageReference && isString(imageReference.source))) {
    return false;
  }

  return true;
}

/**
 * isSearchVideoReference() tests against SearchVideoReference data type.
 * @param data unknown
 * @returns boolean
 */
export function isSearchVideoReference(
  data: unknown
): data is SearchVideoReference {
  const videoReference = data as SearchVideoReference;

  if (!('url' in videoReference && isString(videoReference.url))) {
    return false;
  }

  if (!('title' in videoReference && isString(videoReference.title))) {
    return false;
  }

  if ('height' in videoReference && !isNumber(videoReference.height)) {
    return false;
  }

  if ('width' in videoReference && !isNumber(videoReference.width)) {
    return false;
  }

  if (
    !(
      'displayed_url' in videoReference &&
      isString(videoReference.displayed_url)
    )
  ) {
    return false;
  }

  if (!('duration' in videoReference && isString(videoReference.duration))) {
    return false;
  }

  if (!('snippet' in videoReference && isString(videoReference.snippet))) {
    return false;
  }

  if (!('thumbnail' in videoReference && isString(videoReference.thumbnail))) {
    return false;
  }

  return true;
}

/**
 * isSearchResult() tests data against SearchResult data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchSearchResult(
  data: unknown
): data is ResearchSearchResult {
  const searchResult = data as ResearchSearchResult;

  if ('summary' in searchResult && !isString(searchResult.summary)) {
    return false;
  }

  if ('web_page_references' in searchResult) {
    if (!Array.isArray(searchResult.web_page_references)) {
      return false;
    }

    for (const reference of searchResult.web_page_references) {
      if (!isSearchWebPageReference(reference)) {
        return false;
      }
    }
  }

  if ('image_references' in searchResult) {
    if (!Array.isArray(searchResult.image_references)) {
      return false;
    }

    for (const reference of searchResult.image_references) {
      if (!isSearchImageReference(reference)) {
        return false;
      }
    }
  }

  if ('video_references' in searchResult) {
    if (!Array.isArray(searchResult.video_references)) {
      return false;
    }

    for (const reference of searchResult.video_references) {
      if (!isSearchVideoReference(reference)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * isAiReference() tests data against AiReference data type.
 * @param data unknown
 * @returns boolean
 */
export function isAiReference(data: unknown): data is AiReference {
  const aiReference = data as AiReference;

  if (
    !(
      'source' in aiReference &&
      Object.values(ResearchAi).includes(aiReference.source)
    )
  ) {
    return false;
  }

  if (!('content' in aiReference && isString(aiReference.content))) {
    return false;
  }

  return true;
}

/**
 * isAiResult() tests data against AiResult data type.
 * Att: metadata_info is only needed by BE & not required in FE.
 * @param data unknown
 * @returns boolean
 */
export function isAiResult(data: unknown): data is AiResult {
  const aiResult = data as AiResult;

  if ('summary' in aiResult && !isString(aiResult.summary)) {
    return false;
  }

  if ('references' in aiResult) {
    if (!Array.isArray(aiResult.references)) {
      return false;
    }

    // att: removing this idea at BE
    //for (const reference of aiResult.references) {
    //  if (!isAiReference(reference)) {
    //    return false;
    //  }
    //}

    for (const reference of Object.values(aiResult.references)) {
      if (!isAiContent(reference)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * isResearchCardData() tests data against ResearchCardData data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchCardData(data: unknown): data is ResearchCardData {
  const researchCard = data as ResearchCardData;

  if ('query' in researchCard && !isString(researchCard.query)) {
    return false;
  }

  if (
    'search_result' in researchCard &&
    !isResearchSearchResult(researchCard.search_result)
  ) {
    return false;
  }

  if (
    'generative_ai_result' in researchCard &&
    !isAiResult(researchCard.generative_ai_result)
  ) {
    return false;
  }

  return true;
}

/**
 * isResearchCard() test data against ResearchCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchCard(data: unknown): data is ResearchCard {
  const researchCard = data as ResearchCard;

  if (
    'payload_type' in researchCard &&
    researchCard.payload_type !== 'research-card'
  ) {
    return false;
  }

  if (!('data' in researchCard && isResearchCardData(researchCard.data))) {
    return false;
  }

  return true;
}

/**
 * isAiContent() tests data against AiContent data type
 * @param data unknown
 * @returns boolean
 */
export function isAiContent(data: unknown): data is AiContent {
  const aiContent = data as AiContent;

  if ('content' in aiContent && !isString(aiContent.content)) {
    return false;
  }

  return true;
}

export function isExternalModelReference(
  data: unknown
): data is ExternalModelReference {
  const externalModelReference = data as SearchVideoReference;

  if (!isObject(externalModelReference)) {
    return false;
  }

  if (externalModelReference === null) {
    return false;
  }

  if (!('url' in externalModelReference)) {
    return false;
  }

  if (!('title' in externalModelReference)) {
    return false;
  }

  if (!('icon' in externalModelReference)) {
    return false;
  }

  if (!('content' in externalModelReference)) {
    return false;
  }

  return true;
}
