import { DownloadSimple } from '@phosphor-icons/react';
import { useDownloadFile } from 'src/hooks';

type DownloadButtonProps = {
  url: string;
};

const SVG_SIZE = 24;

export const DownloadButton = ({ url }: DownloadButtonProps) => {
  const { downloadImage } = useDownloadFile();

  return (
    <button
      type="button"
      className="nj-modal-gallery-activity-button"
      onClick={(e) => {
        e.stopPropagation();
        downloadImage(url);
      }}
    >
      <DownloadSimple size={SVG_SIZE} />
    </button>
  );
};
