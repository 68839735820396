import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  SearchVideoReference,
  SearchImageReference,
  isSearchVideoReference,
} from 'src/types';
import { ResearchMediaThumbnail } from '../ResearchMediaThumbnail';
import { useRef } from 'react';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import type { Swiper as SwiperClass } from 'swiper/types';

const SVG_SIZE = 20;

interface ResearchMediaCarouselProps {
  videos: SearchVideoReference[];
  images: SearchImageReference[];
  setCurrentMedia: (index: number) => void;
}

export const ResearchMediaCarousel = ({
  videos,
  images,
  setCurrentMedia,
}: ResearchMediaCarouselProps) => {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="nj-thread-research-card--carousel">
      <div className="nj-thread-research-card--carousel-navigation-wrapper">
        <button ref={prevRef}>
          <CaretLeft size={SVG_SIZE} />
        </button>
        <button ref={nextRef}>
          <CaretRight size={SVG_SIZE} />
        </button>
      </div>

      <Swiper
        modules={[Navigation]}
        spaceBetween={8}
        direction="horizontal"
        slidesPerView="auto"
        className="nj-thread-research-card--carousel-container"
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onInit={(swiper: SwiperClass) => {
          prevRef.current?.addEventListener('click', () => swiper.slidePrev());
          nextRef.current?.addEventListener('click', () => swiper.slideNext());
        }}
      >
        {[...videos, ...images].map((item, index) => (
          <SwiperSlide
            key={item.url}
            className="nj-thread-research-media--slide"
          >
            <ResearchMediaThumbnail
              thumbnail={
                isSearchVideoReference(item) ? item.thumbnail : item.url
              }
              title={item.title}
              isVideo={isSearchVideoReference(item)}
              onClick={() => setCurrentMedia(index)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
