import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './config';
import {
  HTTPValidationError,
  SharedMessage,
  isHTTPValidationError,
  isSharedMessage,
} from 'src/types';

export const sharingApi = createApi({
  reducerPath: 'sharingApi',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getSharedData: build.query<SharedMessage, string>({
      query: (uuid) => uuid,
      transformResponse: (response: SharedMessage) => {
        if (isSharedMessage(response)) {
          return response;
        }

        if (isHTTPValidationError(response)) {
          const { detail } = response as HTTPValidationError;
          if (detail && detail.length > 0) {
            throw Error(detail[0].msg);
          }
        }

        // attn: we can add app wide error handling here
        // for now throwing an error as is
        throw new Error(
          `Unexpected response format: ${JSON.stringify(response)}`
        );
      },
    }),
  }),
});

export const { useGetSharedDataQuery } = sharingApi;
