import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { env } from 'src/env';

const BASE_URL = env.REACT_APP_NINJA_SHARED_API_URL;

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
