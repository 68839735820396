import { X } from '@phosphor-icons/react';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

const SVG_SIZE = 24;

export const CloseButton = () => {
  const { onChangeModalGalleryData } = useContext(SessionContext);

  const handleCloseModal = () => {
    onChangeModalGalleryData(false, null, [], 0);
  };

  return (
    <button
      type="button"
      className="nj-modal-gallery-activity-button"
      onClick={handleCloseModal}
    >
      <X size={SVG_SIZE} />
    </button>
  );
};
