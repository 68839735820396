import { useCollapse } from 'react-collapsed';
import styles from './CollapsibleTitle.module.scss';

type CollapsibleTitleProps = {
  query: string;
};

export const CollapsibleTitle = ({ query }: CollapsibleTitleProps) => {
  const { getToggleProps, isExpanded } = useCollapse();

  const QUERY_MAX_SIZE = 148;
  const longQuery = query.length > QUERY_MAX_SIZE;
  const slicedQuery = `${query.slice(0, QUERY_MAX_SIZE).trim()}...`;

  return (
    <>
      {longQuery && !isExpanded ? slicedQuery : query}
      {longQuery && (
        <button type="button" className={styles.button} {...getToggleProps()}>
          {isExpanded ? (
            <span className={styles.text}>{'View less'}</span>
          ) : (
            <span className={styles.text}>{'View more'}</span>
          )}
        </button>
      )}
    </>
  );
};
