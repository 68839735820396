/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DebugMetadataModerationInfo } from './DebugMetadataModerationInfo';

export type DebugMetadata = {
  payload_type: DebugMetadata.payload_type;
  intent_label: string;
  moderation?: DebugMetadataModerationInfo;
};

export namespace DebugMetadata {
  export enum payload_type {
    DEBUG_METADATA = 'debug-metadata',
  }
}
