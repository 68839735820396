import { Fragment } from 'react';
import {
  SharedContentType,
  SharedMessage as SharedMessageType,
} from 'src/types';
import { SharedMessage } from 'src/components/SharedMessage';
import { SharedVideo } from 'src/components/SharedVideo';
import { useGetSharedData } from 'src/hooks';
import { NinjaLoader } from 'src/components/NinjaLoader';

export const SharedContent = () => {
  const { data, isLoading } = useGetSharedData();

  const isShareableMessage = (data: SharedMessageType) =>
    data.shared_content_type === SharedContentType.MESSAGE;

  const isShareableVideo = (data: SharedMessageType) =>
    data.shared_content_type === SharedContentType.VIDEO;

  if (isLoading) {
    return <NinjaLoader content="Ninja is loading the content" />;
  }

  return (
    <Fragment>
      {data && isShareableMessage(data) && <SharedMessage message={data} />}
      {/* TODO <SharedVideo /> deprecated */}
      {data && isShareableVideo(data) && <SharedVideo video={data} />}
    </Fragment>
  );
};
