import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { store } from './store';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { ForwardRefContextProvider } from 'src/contexts/ForwardRefContext';
import { SessionContextProvider } from 'src/contexts/SessionContext';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'react-responsive-modal/styles.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';
import 'swiper/css';
import './styles/index.scss';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <HashRouter>
        <ForwardRefContextProvider>
          <SessionContextProvider>
            <App />
          </SessionContextProvider>
        </ForwardRefContextProvider>
      </HashRouter>
    </Provider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
