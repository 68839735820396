/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DeepResearchGoogleModel {
  GEMINI_1_0_PRO = 'gemini-1.0-pro',
}
