import { useMemo } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { CalendarCardEvent } from 'src/types';
import { SCHEDULE_ACTION_CREATED } from 'src/constants';

interface ScheduleDateButtonProps {
  activeDateKey: string;
  date: string;
  events: CalendarCardEvent[];
  onClick: (key: string) => void;
}

export const ScheduleDateButton = ({
  activeDateKey,
  date,
  events,
  onClick,
}: ScheduleDateButtonProps) => {
  const formattedDate = useMemo(() => dayjs(date).format('MMM DD'), [date]);

  const createdEventsCount = useMemo(
    () =>
      events.filter(({ action }) => action === SCHEDULE_ACTION_CREATED).length,
    [events]
  );

  const handleButtonClick = () => {
    onClick(date);
  };

  return (
    <button
      className={classNames('nj-thread-schedule-card--date-button', {
        selected: date === activeDateKey,
      })}
      onClick={handleButtonClick}
    >
      <span>{formattedDate}</span>

      <span className="nj-thread-schedule-card--date-button-counter">
        {createdEventsCount}
      </span>
    </button>
  );
};
