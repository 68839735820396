/**
 * Task API Models, will be deprecating for Shareables UI
 */
export type { AiReference } from './models/AiReference';
export type { ApiTask } from './models/ApiTask';
export type { ApiUser } from './models/ApiUser';
export { AvatarVideoPresetName } from './models/AvatarVideoPresetName';
export { AvatarVideosStatus } from './models/AvatarVideosStatus';
export type { AvatarVideos } from './models/AvatarVideos';
export { MessageChannel as CombusMessageChannel } from './combus/models/MessageChannel';
export type { ApiMessage } from './models/ApiMessage';
export type { ApiMessageList } from './models/ApiMessageList';
export type { ApiTaskList } from './models/ApiTaskList';
export type { ApiUserList } from './models/ApiUserList';
export type { Conversation } from './models/Conversation';
export type { CurrencyAmount } from './models/CurrencyAmount';
export { Currency } from './models/Currency';
export type { CreateAvatarVideoRequest } from './models/CreateAvatarVideoRequest';
export { FeedbackType } from './models/FeedbackType';
export type { Guest } from './models/Guest';
export type { LlmSettings } from './models/LlmSettings';
export { LlmResource } from './models/LlmResource';
export type { Meeting } from './models/Meeting';
export type { Message } from './models/Message';
export type { MessageDebugData } from './models/MessageDebugData';
export type { ModelFeedback } from './models/ModelFeedback';
export type { ModelFeedbackDialogue } from './models/ModelFeedbackDialogue';
export type { NegativeMessageFeedbackRequest } from './models/NegativeMessageFeedbackRequest';
export type { NegativeTaskFeedbackRequest } from './models/NegativeTaskFeedbackRequest';
export type { Negotiation } from './models/Negotiation';
export { NinjaEventTypes } from './combus/models/NinjaEventTypes';
export type { PatchApiMessage } from './models/PatchApiMessage';
export type { PatchApiTask } from './models/PatchApiTask';
export type { PDUMessage } from './combus/models/PDUMessage';
export type { PositiveMessageFeedbackRequest } from './models/PositiveMessageFeedbackRequest';
export type { PositiveTaskFeedbackRequest } from './models/PositiveTaskFeedbackRequest';
export { ReservationDetails } from './models/ReservationDetails';
export type { ReservationLog } from './models/ReservationLog';
export type { ResearchTaskPayload } from './models/ResearchTaskPayload';
export { ResearchTaskPayloadKind } from './models/ResearchTaskPayloadKind';
export { SchedulingPayload } from './models/SchedulingPayload';
export type { SchedulingRequest } from './models/SchedulingRequest';
export { SortOrder } from './models/SortOrder';
export { TaskChannel } from './models/TaskChannel';
export { TaskSkill } from './models/TaskSkill';
export { TaskState } from './models/TaskState';
export type { UserChoice } from './models/UserChoice';
export { UserType } from './models/UserType';
export type { UserSavingInsightsSettings } from './models/UserSavingInsightsSettings';
export type { UserSettings } from './models/UserSettings';
export { UserCostSavingDuration } from './models/UserCostSavingDuration';
export type { UserResearchSettings } from './models/UserResearchSettings';
export type { WebSearchSettings } from './models/WebSearchSettings';
export type { ChitChatCard } from './shared/models/ChitChatCard';
export type { RelatedQuestion } from './shared/models/RelatedQuestion';

// TODO: BE has missing models, for now injecting them below:
export enum ResearchAi {
  // TODO deprecated ones
  CHAT_GPT_4 = 'CHAT_GPT_4',
  ANTHROPIC_CLAUDE_2 = 'ANTHROPIC_CLAUDE_2',
  GOOGLE_PALM_2_TEXT_BISON_001 = 'GOOGLE_PALM_2_TEXT_BISON_001',
  GOOGLE_GEMINI_PRO = 'GOOGLE_GEMINI_PRO',
  // new ones
  OPEN_AI = 'OPEN_AI',
  ANTHROPIC = 'ANTHROPIC',
  GOOGLE = 'GOOGLE',
}

/**
 * Shared API is replacing tasks API
 */
export type { AiContent } from './shared/models/AiContent';
export type { AiResult } from './shared/models/AiResult';
export { ConversationRole } from './shared/models/ConversationRole';
export type { Action } from './shared/models/Action';
export { ActionStatue } from './shared/models/ActionStatue';
export type { CalendarCard } from './shared/models/CalendarCard';
export type { CalendarCardEvent } from './shared/models/CalendarCardEvent';
export type { ChoicesCard } from './shared/models/ChoicesCard';
export type { CodeCard } from './shared/models/CodeCard';
export type { CodeCardData } from './shared/models/CodeCardData';
export type { CodeContent } from './shared/models/CodeContent';
export type { CodeTaskCreationCard } from './shared/models/CodeTaskCreationCard';
export { DebugMetadata } from './shared/models/DebugMetadata';
export type { EmailCard } from './shared/models/EmailCard';
export type { EmailAddress as EmailCollaborator } from './shared/models/EmailAddress';
export type { HTTPValidationError } from './shared/models/HTTPValidationError';
export { MessageType } from './shared/models/MessageType';
export { MessageChannel } from './shared/models/MessageChannel';
export type { MessageMetadataResearchMetrics } from './shared/models/MessageMetadataResearchMetrics';
export type { MessageMetadataResearchReferenceCode } from './shared/models/MessageMetadataResearchReferenceCode';
export type { MessageMetadataResearchReferenceLLM } from './shared/models/MessageMetadataResearchReferenceLLM';
export type { MessageMetadataResearchReferencesImageVideo } from './shared/models/MessageMetadataResearchReferencesImageVideo';
export type { MessageMetadataResearchReferencesWebPage } from './shared/models/MessageMetadataResearchReferencesWebPage';
export type { MessageMetadataResearchStatusUpdate } from './shared/models/MessageMetadataResearchStatusUpdate';
export type { MessageMetadataResearchSummary } from './shared/models/MessageMetadataResearchSummary';
export type { MessageMetadataSchedulerMetrics } from './shared/models/MessageMetadataSchedulerMetrics';
export { OperationType } from './shared/models/OperationType';
export type { ProgressUpdate } from './shared/models/ProgressUpdate';
export { ResearchTaskCreationCard } from './shared/models/ResearchTaskCreationCard';
export type { ResearchCard } from './shared/models/ResearchCard';
export type { ResearchCardData } from './shared/models/ResearchCardData';
export type { ResearchData } from './shared/models/ResearchData';
export type { ResearchDataReferenceCode } from './shared/models/ResearchDataReferenceCode';
export type { ResearchDataReferenceVideo } from './shared/models/ResearchDataReferenceVideo';
export type { ResearchDataReferenceImage } from './shared/models/ResearchDataReferenceImage';
export type { ResearchDataReferenceWebPage } from './shared/models/ResearchDataReferenceWebPage';
export type { ResearchDataReferenceLLM } from './shared/models/ResearchDataReferenceLLM';
export type { ResearchResponseMetrics } from './shared/models/ResearchResponseMetrics';
export type { SharedMessage } from './shared/models/SharedMessage';
export type { SchedulerCreateEventMetrics } from './shared/models/SchedulerCreateEventMetrics';
export type { SearchResult as ResearchSearchResult } from './shared/models/SearchResult';
export type { SearchWebPageReference } from './shared/models/SearchWebPageReference';
export type { SearchImageReference } from './shared/models/SearchImageReference';
export type { SearchVideoReference } from './shared/models/SearchVideoReference';
export { SharedContentType } from './shared/models/SharedContentType';
export type { SchedulerCreationCard } from './shared/models/SchedulerCreationCard';
export type { TestPayload } from './shared/models/TestPayload';
export type { ValidationError } from './shared/models/ValidationError';
