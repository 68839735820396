declare global {
  interface Window {
    env: EnvType;
  }
}

type EnvType = {
  REACT_APP_ENVIRONMENT: string;
  REACT_APP_NINJA_SHARED_API_URL: string;
  REACT_APP_NINJA_SHARED_URL: string;
  REACT_APP_NINJA_SHARED_LOGIN_URL: string;
  REACT_APP_NINJA_SHARED_SIGNUP_URL: string;
};

export const env: EnvType = { ...process.env, ...window.env };
