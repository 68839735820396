import { useState, createContext } from 'react';
import { ModalGalleryType, ModalGalleryList } from 'src/types';

export type SessionContextType = {
  isVisibleModalGallery: boolean;
  onChangeModalGalleryData: (
    isShow: boolean,
    modalType: ModalGalleryType | null,
    data: ModalGalleryList,
    defaultSelectedIndex: number
  ) => void;
  currentModalGalleryType: ModalGalleryType | null;
  currentListOfGalleryItems: ModalGalleryList;
  defaultSelectedGalleryIndex: number;
};

type SessionContextProviderProps = {
  children: React.ReactNode;
};

const SessionContext = createContext<SessionContextType>({
  isVisibleModalGallery: false,
  onChangeModalGalleryData: () => undefined,
  currentModalGalleryType: null,
  currentListOfGalleryItems: [],
  defaultSelectedGalleryIndex: 0,
});

const SessionContextProvider = ({ children }: SessionContextProviderProps) => {
  const [defaultSelectedGalleryIndex, setDefaultSelectedGalleryIndex] =
    useState(0);

  const [isVisibleModalGallery, setIsVisibleModalGallery] =
    useState<boolean>(false);
  const [currentModalGalleryType, setCurrentModalGalleryType] =
    useState<ModalGalleryType | null>(null);
  const [currentListOfGalleryItems, setCurrentListOfGalleryItems] =
    useState<ModalGalleryList>([]);

  const handleChangeModalGallery = (
    isShowModal: boolean,
    modalContentType: ModalGalleryType | null,
    listOfItems: ModalGalleryList,
    defaultSelectedGalleryIndex: number
  ) => {
    setCurrentListOfGalleryItems(listOfItems);
    setDefaultSelectedGalleryIndex(defaultSelectedGalleryIndex);
    setCurrentModalGalleryType(modalContentType);
    setIsVisibleModalGallery(isShowModal);
  };

  return (
    <SessionContext.Provider
      value={{
        isVisibleModalGallery,
        onChangeModalGalleryData: handleChangeModalGallery,
        currentModalGalleryType,
        currentListOfGalleryItems,
        defaultSelectedGalleryIndex,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContextProvider };
export default SessionContext;
