import { Routes, Route } from 'react-router-dom';
import { PageLayout } from 'src/components/PageLayout';
import { ContentPageWithHelmet } from 'src/pages/ContentPage';
import { NotFoundPage } from './NotFound';
import { useDocumentHeightChange } from 'src/hooks';

function App() {
  useDocumentHeightChange();
  return (
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route index element={<NotFoundPage />} />
        <Route path=":uuid" element={<ContentPageWithHelmet />} />
      </Route>
    </Routes>
  );
}

export default App;
