import { ArrowDown } from '@phosphor-icons/react';

const ICON_SIZE = 20;

type FabProps = {
  onClick: () => void;
};

export const Fab = ({ onClick }: FabProps) => {
  return (
    <div className="nj-button--fab" onClick={onClick}>
      <ArrowDown size={ICON_SIZE} weight="regular" color="currentColor" />
    </div>
  );
};
