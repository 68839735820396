import {
  SharedMessage,
  MessageType,
  OperationType,
  ConversationRole,
  SharedContentType,
  HTTPValidationError,
  MessageChannel,
  ValidationError,
} from '../api';
import {
  isProgressUpdate,
  isTestPayload,
  isResearchData,
  isChitChatCard,
} from '../api.guard';
import { isObject, isString, isNumber } from '../general.guard';

/**
 * isValidationError() guards against ValidationError data type
 * @param data unknown
 * @returns boolean
 */
export function isValidationError(data: unknown): data is ValidationError {
  const error = data as ValidationError;

  if ('loc' in error) {
    if (!Array.isArray(error.loc)) {
      return false;
    }

    for (const loc of error.loc) {
      if (!(isString(loc) || isNumber(loc))) {
        return false;
      }
    }
  }

  if (!('msg' in error && isString(error.msg))) {
    return false;
  }

  if (!('type' in error && isString(error.type))) {
    return false;
  }

  return true;
}

/**
 * isHTTPValidationError() guards against HTTPValidationError data type
 * @param data unknown
 * @returns boolean
 */
export function isHTTPValidationError(
  data: unknown
): data is HTTPValidationError {
  const error = data as HTTPValidationError;

  if (!isObject(error)) {
    return false;
  }

  if ('detail' in error) {
    if (!Array.isArray(error.detail)) {
      return false;
    }

    for (const detail of error.detail) {
      if (!isValidationError(detail)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * isSharedMessage() typeguard returns true if data is a SharedMessage
 * @param data unknown
 * @returns boolean
 */
export function isSharedMessage(data: unknown): data is SharedMessage {
  const message = data as SharedMessage;

  if (!isObject(message)) {
    return false;
  }

  if (
    'payload' in message &&
    !(
      isResearchData(message.payload) ||
      isProgressUpdate(message.payload) ||
      isTestPayload(message.payload) ||
      isChitChatCard(message.payload)
    )
  ) {
    return false;
  }

  if ('video_title' in message && !isString(message.video_title)) {
    return false;
  }

  if ('video_resolution' in message && !isString(message.video_resolution)) {
    return false;
  }

  if (
    'message_type' in message &&
    !(
      isString(message.message_type) &&
      Object.values(MessageType).includes(message.message_type)
    )
  ) {
    return false;
  }

  if (
    'operation_type' in message &&
    !(
      isString(message.operation_type) &&
      Object.values(OperationType).includes(message.operation_type)
    )
  ) {
    return false;
  }

  if (
    'channel' in message &&
    !(
      isString(message.channel) &&
      Object.values(MessageChannel).includes(message.channel)
    )
  ) {
    return false;
  }

  if (
    'role' in message &&
    !(
      isString(message.role) &&
      Object.values(ConversationRole).includes(message.role)
    )
  ) {
    return false;
  }

  if ('tag' in message && !isString(message.tag)) {
    return false;
  }

  if ('timestamp' in message && !isString(message.timestamp)) {
    return false;
  }

  if ('click_count' in message && !isNumber(message.click_count)) {
    return false;
  }

  if ('expiration_time' in message && !isNumber(message.expiration_time)) {
    return false;
  }

  if (
    'shared_content_time' in message &&
    !(
      isString(message.shared_content_time) &&
      Object.values(SharedContentType).includes(
        message.shared_content_time as SharedContentType
      )
    )
  ) {
    return false;
  }

  if ('refined_query' in message && !isString(message.refined_query)) {
    return false;
  }

  if ('original_query' in message && !isString(message.original_query)) {
    return false;
  }

  if ('clean_query' in message && !isString(message.clean_query)) {
    return false;
  }

  return true;
}
