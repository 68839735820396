import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

type Breakpoints =
  | 'isUltraWideScreen'
  | 'isDesktop'
  | 'isTablet'
  | 'isMobile'
  | 'isMobileOrTablet'
  | 'isTabletAndUp'
  | 'isDesktopAndUp'
  | 'isLaptopAndUp'
  | 'isLaptopLAndUp'
  | 'isDesktopLAndUp'
  | 'isDesktopXLAndUp'
  | 'isDesktopXLAvatarAndUp';

type UseBreakpoint = Record<Breakpoints, boolean>;

// Main breakpoints
const BREAKPOINT_ULTRA_WIDE = 1920;
const BREAKPOINT_DESKTOP = 1280;
const BREAKPOINT_TABLET = 768;

// Additional breakpoints
const BREAKPOINT_DESKTOP_XL = 1440;
const BREAKPOINT_DESKTOP_L = 1360;
const BREAKPOINT_LAPTOP_L = 1024;
const BREAKPOINT_LAPTOP = 992;

// Custom breakpoints
const CUSTOM_BREAKPOINT_DESKTOP_XL_AVATAR = 1515;

export const useBreakpoint = (): UseBreakpoint => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_TABLET - 1 });
  const isMobileOrTablet = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP - 1 });
  const isTablet = useMediaQuery({
    minWidth: BREAKPOINT_TABLET,
    maxWidth: BREAKPOINT_DESKTOP - 1,
  });
  const isDesktop = useMediaQuery({
    minWidth: BREAKPOINT_DESKTOP,
    maxWidth: BREAKPOINT_ULTRA_WIDE - 1,
  });
  const isUltraWideScreen = useMediaQuery({ minWidth: BREAKPOINT_ULTRA_WIDE });
  const isTabletAndUp = useMediaQuery({ minWidth: BREAKPOINT_TABLET });
  const isLaptopAndUp = useMediaQuery({ minWidth: BREAKPOINT_LAPTOP });
  const isLaptopLAndUp = useMediaQuery({ minWidth: BREAKPOINT_LAPTOP_L });
  const isDesktopAndUp = useMediaQuery({ minWidth: BREAKPOINT_DESKTOP });
  const isDesktopLAndUp = useMediaQuery({ minWidth: BREAKPOINT_DESKTOP_L });
  const isDesktopXLAndUp = useMediaQuery({ minWidth: BREAKPOINT_DESKTOP_XL });
  const isDesktopXLAvatarAndUp = useMediaQuery({
    minWidth: CUSTOM_BREAKPOINT_DESKTOP_XL_AVATAR,
  });

  return useMemo(
    () => ({
      isMobile,
      isMobileOrTablet,
      isTablet,
      isDesktop,
      isUltraWideScreen,
      isTabletAndUp,
      isLaptopAndUp,
      isLaptopLAndUp,
      isDesktopAndUp,
      isDesktopLAndUp,
      isDesktopXLAndUp,
      isDesktopXLAvatarAndUp,
    }),
    [
      isMobile,
      isMobileOrTablet,
      isTablet,
      isDesktop,
      isUltraWideScreen,
      isTabletAndUp,
      isLaptopAndUp,
      isLaptopLAndUp,
      isDesktopAndUp,
      isDesktopLAndUp,
      isDesktopXLAndUp,
      isDesktopXLAvatarAndUp,
    ]
  );
};
