import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TabProps } from '../Tab';
import styles from './TabList.module.scss';

export interface TabListProps<T extends string> {
  activeTab?: T;
  onTabClick?: (tab: T) => void;
  children: ReactNode;
  className?: string;
}

export const TabList = <T extends string>({
  activeTab,
  children,
  onTabClick,
  className,
}: TabListProps<T>) => {
  return (
    <div className={classNames(styles.root, className)}>
      <Swiper init={false} spaceBetween={4} slidesPerView="auto">
        {Children.map(children, (child, index) => {
          if (!isValidElement(child)) {
            return null;
          }

          const childType = (child.type as React.FC).displayName;

          if (childType === 'Tab') {
            const clonedChild = cloneElement(
              child as ReactElement<TabProps<T>>,
              {
                activeTab,
                onTabClick,
              },
            );

            return <SwiperSlide key={index}>{clonedChild}</SwiperSlide>;
          }

          return <SwiperSlide key={index}>{child}</SwiperSlide>;
        })}
      </Swiper>
    </div>
  );
};

TabList.displayName = 'TabList';
