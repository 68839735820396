import { SourceTypesLabels, ResearchAi } from 'src/types';
import { Icon } from 'src/components/Icon';

const SVG_SIZE = 24;

export const researchSourcesSet: Record<
  ResearchAi,
  { title: string; icon: JSX.Element; order: number }
> = {
  [ResearchAi.OPEN_AI]: {
    title: SourceTypesLabels.OPEN_AI,
    icon: <Icon type="chatGPTLogo" size={SVG_SIZE} />,
    order: 1,
  },

  [ResearchAi.ANTHROPIC]: {
    title: SourceTypesLabels.ANTHROPIC,
    icon: <Icon type="claudeLogo" size={SVG_SIZE} />,
    order: 2,
  },

  [ResearchAi.GOOGLE]: {
    title: SourceTypesLabels.GOOGLE,
    icon: <Icon type="googleGeminiProLogo" size={SVG_SIZE} />,
    order: 3,
  },

  // TODO deprecated ones:
  [ResearchAi.CHAT_GPT_4]: {
    title: SourceTypesLabels.CHAT_GPT4,
    icon: <Icon type="chatGPTLogo" size={SVG_SIZE} />,
    order: 4,
  },

  [ResearchAi.ANTHROPIC_CLAUDE_2]: {
    title: SourceTypesLabels.ANTHROPIC,
    icon: <Icon type="claudeLogo" size={SVG_SIZE} />,
    order: 5,
  },

  [ResearchAi.GOOGLE_GEMINI_PRO]: {
    title: SourceTypesLabels.GOOGLE_GEMINI_PRO,
    icon: <Icon type="googleGeminiProLogo" size={SVG_SIZE} />,
    order: 6,
  },

  [ResearchAi.GOOGLE_PALM_2_TEXT_BISON_001]: {
    title: SourceTypesLabels.GOOGLE_PALM2,
    icon: <Icon type="googlePalmLogo" size={SVG_SIZE} />,
    order: 7,
  },
};
