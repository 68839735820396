import classNames from 'classnames';
import { ShareButton } from 'src/components/ShareButton';

interface PlayerWrapperProps {
  videoTitle: string;
  inProgress: boolean;
  children: React.ReactNode;
}

export const PlayerWrapper = ({
  videoTitle,
  inProgress,
  children,
}: PlayerWrapperProps) => {
  return (
    <div className="nj-video--player-wrapper">
      <div
        className={classNames('nj-video--player-header', {
          hide: inProgress,
        })}
      >
        <p className="nj-video--player-header-title">{videoTitle}</p>
        <ShareButton className="nj-video--share-button" weight="fill" />
      </div>

      {children}
    </div>
  );
};
