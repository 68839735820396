import { useMemo } from 'react';
import { SharedMessage } from 'src/types';
import { Helmet } from 'react-helmet-async';
import { ContentPage } from './ContentPage';
import { useGetSharedData } from 'src/hooks';

export const ContentPageWithHelmet = () => {
  const { data } = useGetSharedData();

  const {
    refined_query,
    clean_query,
    original_query,
    content = '',
  } = (data as SharedMessage) || {};

  const title = useMemo(() => {
    return refined_query || clean_query || original_query || 'Meet Ninja';
  }, [refined_query, clean_query, original_query]);

  const description = useMemo(() => {
    return content
      ? `${content.slice(0, 195)}...`
      : 'Your Personal AI that gets tasks done';
  }, [content]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <ContentPage />
    </>
  );
};
