import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
  CalendarCard,
  SharedMessage as SharedMessageType,
  SchedulerCreationCard,
} from 'src/types';
import { uppercaseFirstLetter } from 'src/utils';
import { ScheduleDateButton } from './components/ScheduleDateButton';
import { ScheduleEventGroup } from './components/ScheduleEventGroup';
import { MessageContent } from '../MessageContent';

interface SchedulerCardProps {
  message: SharedMessageType;
}

export const SchedulerCard = ({ message }: SchedulerCardProps) => {
  const { calendar_card = {}, details = '' } =
    message.payload as SchedulerCreationCard;
  const { summary = '', dates: calendarDates } = calendar_card as CalendarCard;
  const [activeDateKey, setActiveDateKey] = useState<string | null>(null);

  const sortedDateKeys = useMemo(
    () =>
      calendarDates
        ? Object.keys(calendarDates).sort((a, b) => dayjs(a).diff(dayjs(b)))
        : [],
    [calendarDates]
  );

  const activeKey = activeDateKey || sortedDateKeys[0];

  return (
    <div className="nj-thread-schedule-card">
      {details && <MessageContent content={details} />}

      {calendarDates && (
        <>
          <div className="nj-thread-schedule-card--header">
            <span className="nj-thread-schedule-card--summary">
              {uppercaseFirstLetter(summary) || 'Upcoming event'}
            </span>
          </div>

          <div className="nj-thread-schedule-card--date-panel">
            {sortedDateKeys.map((item) => (
              <ScheduleDateButton
                key={item}
                date={item}
                activeDateKey={activeKey}
                events={calendarDates[activeKey]}
                onClick={setActiveDateKey}
              />
            ))}
          </div>

          <p className="nj-thread-schedule-card--current-date">
            {dayjs(activeKey).format('dddd, MMM D, YYYY')}
          </p>

          <ScheduleEventGroup
            dateKeys={sortedDateKeys}
            eventsByDate={calendarDates}
            activeDateKey={activeKey}
          />
        </>
      )}
    </div>
  );
};
